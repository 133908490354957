<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div
    class="customDress-container"
  >
    <b-button
      v-b-toggle="`collapse-${idx}`"
      class="m-1"
      variant="light"
    >
      <b-icon-arrows-expand />
    </b-button>
    <!-- <div>
      <b-form-radio
        :id="'index'"
        v-model="dress.defaultBodyMeasurements"
        name="plain-inline"
        :value="true"
        :unchecked-value="false"
      />
    </div> -->
    <section
      class="custom-modal__wrapper"
      style="margin-bottom: 20px; flex-direction: column"
    >
      <div
        v-if="dress.existingSizes == false"
        style="display: flex"
      >
        <h4
          class="headline"
        >
          {{ $t('CustomSize') }}
        </h4>
      </div>
      <div
        v-else
        style="display: flex"
      >
        <h4
          class="headline"
        >
          {{ $t('StandardSize') }}
        </h4>
      </div>
      <div style="display: flex;">
        <b-collapse
          :id="`collapse-${idx}`"
          visible
        >
          <p style="padding: 0px; margin-right: 12px;margin-bottom:0px;">
            <b-form-checkbox
              id="checkbox-sizes"
              v-model="dress.existingSizes"
              :name="`checkbox-${idx}`"
              :value="true"
              :unchecked-value="false"
            >
              {{ $t('RegularSizes') }}
            </b-form-checkbox>
          </p>
        </b-collapse>
        <b-form-checkbox
          v-if="!dress.existingSizes"
          :key="idx"
          v-model="selectedValue"
          name="plain-inline"
          :value="true"
          :unchecked-value="false"
          @change="updateSelectedValue"
        > {{ $t('SetDefaultSize') }}</b-form-checkbox>
      </div>
      <div
        v-if="dress.existingSizes == false && client == 'ValdrinSahiti'"
        class="skica-container"
      >
        <b-collapse
          :id="`collapse-${idx}`"
          visible
        >
          <div
            class="front-body"
          >
            <div class="betweenCol">
              <div class="input">
                <span>{{ $t('toraks') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.thorax"
                  :placeholder="$t('')"
                  class="toraks1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Bust') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.aroundBust"
                  :placeholder="$t('')"
                  class="rrethiGjoksit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('UnderBust') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.aroundUnderBust"
                  :placeholder="$t('')"
                  class="rrethiNenGjoksit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Abdomen') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.waist"
                  :placeholder="$t('')"
                  class="beli1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Hips') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.aroundHips"
                  :placeholder="$t('')"
                  class="vithet1"
                />
              </div>
              <div class="input">
                <span>{{ $t('BustReduction') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.aroundLeg"
                  :placeholder="$t('')"
                  class="rrethiKembes1"
                />
              </div>
              <div class="input">
                <span>{{ $t('UnderBustReduction') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.aroundKnee"
                  :placeholder="$t('')"
                  class="rrethiGjurit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('FrontBody') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.ankle"
                  :placeholder="$t('')"
                  class="zoguKembeve1"
                />
              </div>


            </div>
            <div class="betweenCol">


              <div class="input">
                <span>{{ $t('BackBody') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.breastReduction"
                  :placeholder="$t('')"
                  class="reniaGjoksit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Shoulders') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.chestDistance"
                  :placeholder="$t('')"
                  class="distancaGjoksit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('ShouldersSeam') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.sleeveLength"
                  :placeholder="$t('')"
                  class="distancaGjoksit1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Distance') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.interBust"
                  :placeholder="$t('')"
                  class="nderGjoks1"
                />
              </div>

              <div class="input">
                <span>{{ $t('ButtocksReduction') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.frontBody"
                  :placeholder="$t('')"
                  class="trupiPara1"
                />
              </div>
              <div class="input">
                <span>{{ $t('Height') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.length1"
                  :placeholder="$t('')"
                  class="gjatesia11"
                />
              </div>
              <div class="input">
                <span>{{ $t('3QuartersLength') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.length2"
                  :placeholder="$t('')"
                  class="gjatesia21"
                />
              </div>
            </div>
            <div class="betweenCol">

              <div class="input">
                <span>{{ $t('ChanelLength') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.length3"
                  :placeholder="$t('')"
                  class="gjatesia31"
                />
              </div>
              <div class="input">
                <span>{{ $t('MiniLength') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.length4"
                  :placeholder="$t('')"
                  class="gjatesia41"
                />
              </div>
              <div class="input">
                <span>{{ $t('SleeveCircumference') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.aroundSleeve"
                  :placeholder="$t('')"
                  class="rrethiMenges1"
                />
              </div>
              <div class="input">
                <span>{{ $t('SleeveLength') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.elbow"
                  :placeholder="$t('')"
                  class="brryli1"
                />
              </div>
              <div class="input">
                <span>{{ $t('M.3QuartersLength') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.wrist"
                  :placeholder="$t('')"
                  class="kyçi1"
                />
              </div>
              <div class="input">
                <span>{{ $t('ElbowCircumference') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.aroundNeck"
                  :placeholder="$t('')"
                  class="rrethiQafes1"
                />
              </div>
              <div class="input">
                <span>{{ $t('WristCircumference') }}</span>
                <b-form-input
                  v-model="dress.sizesDto.backBody"
                  :placeholder="$t('')"
                  class="trupiPrapa1"
                />
              </div>
            </div>
          </div>

        </b-collapse>
      </div>
      <div
        v-if="dress.existingSizes == false && client != 'ValdrinSahiti'"
        class="skica-container"
      >
        <b-collapse
          :id="`collapse-${idx}`"
          visible
        >
          <div
            class="btn-con"
          >
            <button
              type="button"
              class="button"
              @click="dress.showFrontBody = !dress.showFrontBody"
            >
              {{ $t('FlipBody') }}
            </button>
          </div>
          <div
            v-if="dress.showFrontBody"
            class="front-body"
          >
            <img
              src="../assets/images/front-body.svg"
              alt=""
            >
            <div class="toraks">
              <b-form-input
                v-model="dress.sizesDto.thorax"
                :placeholder="$t('toraks')"
                class="toraks1"
              />
              <b-form-radio
                v-model="radioStates.thorax"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="dress.sizesDto.thorax === '' || dress.sizesDto.thorax === null"
              />
            </div>
            <div class="rrethiGjoksit">
              <b-form-input
                v-model="dress.sizesDto.aroundBust"
                :placeholder="$t('rrethiGjoksit')"
                class="rrethiGjoksit1"
              />
              <b-form-radio
                v-model="radioStates.aroundBust"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="dress.sizesDto.aroundBust === '' || dress.sizesDto.aroundBust === null"
              />
            </div>
            <div class="rrethiNenGjoksit">
              <b-form-input
                v-model="dress.sizesDto.aroundUnderBust"
                :placeholder="$t('rrethiNenGjoksit')"
                class="rrethiNenGjoksit1"
              />
              <b-form-radio
                v-model="radioStates.aroundUnderBust"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="dress.sizesDto.aroundUnderBust === '' || dress.sizesDto.aroundUnderBust === null"
              />
            </div>
            <div class="beli">
              <b-form-input
                v-model="dress.sizesDto.waist"
                :placeholder="$t('beli')"
                class="beli1"
              />
              <b-form-radio
                v-model="radioStates.waist"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="dress.sizesDto.waist === '' || dress.sizesDto.waist === null"
              />
            </div>
            <div class="vithet">
              <b-form-radio
                v-model="radioStates.aroundHips"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="dress.sizesDto.aroundHips === '' || dress.sizesDto.aroundHips === null"
              />
              <b-form-input
                v-model="dress.sizesDto.aroundHips"
                :placeholder="$t('RrethiVitheve')"
                class="vithet1"
              />
            </div>
            <div class="rrethiKembes">
              <b-form-radio
                v-model="radioStates.aroundLeg"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="dress.sizesDto.aroundLeg === '' || dress.sizesDto.aroundLeg === null"
              />
              <b-form-input
                v-model="dress.sizesDto.aroundLeg"
                :placeholder="$t('rrethiKembes')"
                class="rrethiKembes1"
              />
            </div>
            <div class="rrethiGjurit">
              <b-form-radio
                v-model="radioStates.aroundKnee"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="dress.sizesDto.aroundKnee === '' || dress.sizesDto.aroundKnee === null"
              />
              <b-form-input
                v-model="dress.sizesDto.aroundKnee"
                :placeholder="$t('rrethiGjurit')"
                class="rrethiGjurit1"
              />
            </div>
            <div class="zoguKembeve">
              <b-form-radio
                v-model="radioStates.ankle"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="dress.sizesDto.ankle === '' || dress.sizesDto.ankle === null"
              />
              <b-form-input
                v-model="dress.sizesDto.ankle"
                :placeholder="$t('zoguKembeve')"
                class="zoguKembeve1"
              />
            </div>

            <div class="reniaGjoksit">
              <b-form-radio
                v-model="radioStates.breastReduction"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="dress.sizesDto.breastReduction === '' || dress.sizesDto.breastReduction === null"
              />
              <b-form-input
                v-model="dress.sizesDto.breastReduction"
                :placeholder="$t('reniaGjoksit')"
                class="reniaGjoksit1"
              />
            </div>
            <div class="distancaGjoksit">
              <b-form-radio
                v-model="radioStates.chestDistance"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="dress.sizesDto.chestDistance === '' || dress.sizesDto.chestDistance === null"
              />
              <b-form-input
                v-model="dress.sizesDto.chestDistance"
                :placeholder="$t('distancaGjoksit')"
                class="distancaGjoksit1"
              />
            </div>
            <div class="nderGjoks">
              <b-form-radio
                v-model="radioStates.interBust"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="dress.sizesDto.interBust === '' || dress.sizesDto.interBust === null"
              />
              <b-form-input
                v-model="dress.sizesDto.interBust"
                :placeholder="$t('nderGjoks')"
                class="nderGjoks1"
              />
            </div>
            <div class="trupiPara">
              <b-form-radio
                v-model="radioStates.frontBody"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="dress.sizesDto.frontBody === '' || dress.sizesDto.frontBody === null"
              />
              <b-form-input
                v-model="dress.sizesDto.frontBody"
                :placeholder="$t('trupiPara')"
                class="trupiPara1"
              />
            </div>
            <div class="gjatesia1">
              <b-form-input
                v-model="dress.sizesDto.length1"
                :placeholder="$t('gjatesia1')"
                class="gjatesia11"
              />
              <b-form-radio
                v-model="radioStates.length1"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="dress.sizesDto.length1 === '' || dress.sizesDto.length1 === null"
              />
            </div>
            <div class="gjatesia2">
              <b-form-input
                v-model="dress.sizesDto.length2"
                :placeholder="$t('gjatesia2')"
                class="gjatesia21"
              />
              <b-form-radio
                v-model="radioStates.length2"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="dress.sizesDto.length2 === '' || dress.sizesDto.length2 === null"
              />
            </div>
            <div class="gjatesia3">
              <b-form-input
                v-model="dress.sizesDto.length3"
                :placeholder="$t('gjatesia3')"
                class="gjatesia31"
              />
              <b-form-radio
                v-model="radioStates.length3"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="dress.sizesDto.length3 === '' || dress.sizesDto.length3 === null"
              />
            </div>
            <div class="gjatesia4">
              <b-form-input
                v-model="dress.sizesDto.length4"
                :placeholder="$t('gjatesia4')"
                class="gjatesia41"
              />
              <b-form-radio
                v-model="radioStates.length4"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="dress.sizesDto.length4 === '' || dress.sizesDto.length4 === null"
              />
            </div>
          </div>
          <div
            v-if="!dress.showFrontBody"
            class="back-body"
          >
            <img
              src="../assets/images/back-body.svg"
              alt=""
            >
            <div class="rrethiMenges">
              <b-form-radio
                v-model="radioStates.aroundSleeve"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="dress.sizesDto.aroundSleeve === '' || dress.sizesDto.aroundSleeve === null"
              />
              <b-form-input
                v-model="dress.sizesDto.aroundSleeve"
                :placeholder="$t('rrethiMenges')"
                class="rrethiMenges1"
              />
            </div>
            <div class="brryli">
              <b-form-radio
                v-model="radioStates.elbow"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="dress.sizesDto.elbow === '' || dress.sizesDto.elbow === null"
              />
              <b-form-input
                v-model="dress.sizesDto.elbow"
                :placeholder="$t('brryli')"
                class="brryli1"
              />
            </div>
            <div class="kyçi">
              <b-form-input
                v-model="dress.sizesDto.wrist"
                :placeholder="$t('kyqi')"
                class="kyçi1"
              />
              <b-form-radio
                v-model="radioStates.wrist"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="dress.sizesDto.wrist === '' || dress.sizesDto.wrist === null"
              />
            </div>
            <div class="rrethiQafes">
              <b-form-radio
                v-model="radioStates.aroundNeck"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="dress.sizesDto.aroundNeck === '' || dress.sizesDto.aroundNeck === null"
              />
              <b-form-input
                v-model="dress.sizesDto.aroundNeck"
                :placeholder="$t('rrethiQafes')"
                class="rrethiQafes1"
              />
            </div>
            <div class="trupiPrapa">
              <b-form-radio
                v-model="radioStates.backBody"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="dress.sizesDto.backBody === '' || dress.sizesDto.backBody === null"
              />
              <b-form-input
                v-model="dress.sizesDto.backBody"
                :placeholder="$t('trupiPrapa')"
                class="trupiPrapa1"
              />
            </div>
            <div class="supet">
              <b-form-input
                v-model="dress.sizesDto.shoulders"
                :placeholder="$t('supet')"
                class="supet1"
              />
              <b-form-radio
                v-model="radioStates.shoulders"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="dress.sizesDto.shoulders === '' || dress.sizesDto.shoulders === null"
              />
            </div>
            <div class="shpina">
              <b-form-input
                v-model="dress.sizesDto.back"
                :placeholder="$t('shpina')"
                class="shpina1"
              />
              <b-form-radio
                v-model="radioStates.back"
                :value="true"
                :unchecked-value="false"
                class="pink-radio"
                :disabled="dress.sizesDto.back === '' || dress.sizesDto.back === null"
              />
            </div>
            <div class="reniaVitheve">
              <b-form-radio
                v-model="radioStates.declineOfTheHips"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="dress.sizesDto.declineOfTheHips === '' || dress.sizesDto.declineOfTheHips === null"
              />
              <b-form-input
                v-model="dress.sizesDto.declineOfTheHips"
                :placeholder="$t('reniaVitheve')"
                class="reniaVitheve1"
              />
            </div>
            <div class="gjatesiaMenges">
              <b-form-radio
                v-model="radioStates.sleeveLength"
                :value="true"
                :unchecked-value="false"
                class="pink-radio right"
                :disabled="dress.sizesDto.sleeveLength === '' || dress.sizesDto.sleeveLength === null"
              />
              <b-form-input
                v-model="dress.sizesDto.sleeveLength"
                :placeholder="$t('gjatesiaMenges')"
                class="gjatesiaMenges1"
              />
            </div>
          </div>
        </b-collapse>
      </div>
      <div
        v-if="dress.existingSizes == true"
      >
        <b-collapse
          :id="
            `collapse-${idx}`"
          visible
        >
          <b-form-group
            id="input-select-1"
            :label="`${$t('Selectdresssize')}:`"
            label-for="select-1"
          >
            <vue-select
              v-model="dress.standardSize"
              style="width: 30%"
              :options="filteredSizeTypes"
              :reduce="e => e.name"
              required
              :placeholder="$t('SelectSize')"
              label="name"
            />
            <p
              v-if="dress.showDressError"
              style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
            >
              {{ $t('SelectSize') }}
            </p>
          </b-form-group>
        </b-collapse>
      </div>
    </section>
    <div style="display: inline-flex; align-items: baseline">
      <h4
        v-if="!dress.eArticle"
        class="headline"
      >
        {{ dress.title }}
      </h4>
      <b-input
        v-else
        v-model="dress.title"
        type="text"
      />
      <div
        class="inside-actions"
        @click="dress.eArticle = !dress.eArticle"
      >
        <b-icon-pencil v-if="dress.eArticle == false" />
        <b-icon-check2 v-if="dress.eArticle == true" />
      </div>
      <div
        class="inside-actions"
        @click="removeCustomDress(idx)"
      >
        <b-icon-trash />
      </div>
    </div>
    <div class="">
      <b-button
        v-b-toggle="`lapse-${idx}`"
        class="m-1"
        variant="light"
      >
        <b-icon-arrows-expand />
      </b-button>
      <span style="font-weight: 500">{{ $t('MaterialVariants') }}</span>
      <p
        v-if="dress.duplicateMV"
        style="color: red; margin: 0;"
      >
        {{ $t('DuplicateMaterial') }}
      </p>
      <p
        v-if="dress.isEmpty"
        style="color: red; margin: 0;"
      >
        {{ $t('EmptyMaterial') }}
      </p>
      <b-collapse
        :id="`lapse-${idx}`"
        visible
      >
        <div
          class="modal-card__wrapper"
          style="align-items: center"
        >
          <div
            v-for="(item, index2) in dress.formItem"
            :key="index2"
            class="modal-card"
          >
            <b-form-group
              id="input-group-3"
              :label="`${$t('SelectMaterialType')}:`"
            >
              <i
                class="modal-card__remove"
                @click="removeMaterialItemFromCD(index2)"
              >
                <b-icon-trash />
              </i>
              <div
                class="flex radio-form"
                style="justify-content: space-between;"
              >
                <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px;">
                  <div
                    :id="`one${index2}`"
                    class="flex aic"
                    @click="resetMeter(item)"
                  >
                    <input
                      v-model="item.measurementType"
                      type="radio"
                      value="Meter"
                    >
                    <label :for="`one${index2}`">{{ $t('Meter') }}</label>
                  </div>
                  <div
                    :id="`one${index2}`"
                    class="flex aic"
                    @click="resetPackage(item)"
                  >
                    <input
                      v-model="item.measurementType"
                      type="radio"
                      value="Package"
                    >
                    <label :for="`one${index2}`">{{ $t('Package') }}</label>
                  </div>
                  <div
                    :id="`one${index2}`"
                    class="flex aic"
                    @click="resetSet(item)"
                  >
                    <input
                      v-model="item.measurementType"
                      type="radio"
                      value="Set"
                    >
                    <label :for="`one${index2}`">{{ $t('Set') }}</label>
                  </div>
                  <div
                    :id="`one${index2}`"
                    class="flex aic"
                    @click="resetPair(item)"
                  >
                    <input
                      v-model="item.measurementType"
                      type="radio"
                      value="Pair"
                    >
                    <label :for="`one${index2}`">{{ $t('Pair') }}</label>
                  </div>
                  <div
                    :id="`one${index2}`"
                    class="flex aic"
                    @click="resetVerse(item)"
                  >
                    <input
                      v-model="item.measurementType"
                      type="radio"
                      value="Verse"
                    >
                    <label :for="`one${index2}`">{{ $t('Beaded strand') }}</label>
                  </div>
                  <div
                    :id="`two${index2}`"
                    class="flex aic"
                    @click="resetPart(item)"
                  >
                    <input
                      v-model="item.measurementType"
                      type="radio"
                      value="Part"
                    >
                    <label :for="`two${index2}`">  {{ $t('Part') }}</label>
                  </div>
                </div>
                <td>
                  <img
                    v-if="item.name != null && item.name != '' "
                    v-b-tooltip="$t('ClickToViewPhoto')"
                    style="height: 30px; border-radius: 6px;cursor: pointer "
                    src="../assets/images/imageicon.svg"
                    alt=""
                    @click.stop="getAllImages(item.name.materialVariantId)"
                  >
                </td>
              </div>
            </b-form-group>
            <div class="search-toggle">

              <p>{{ $t('SearchBy') }}</p>
              <div>
                <p
                  :class="{ active2: toggleSearch === 'MaterialName' }"
                  @click="showMaterialName(), toggleSearch = 'MaterialName'"
                >
                  {{ $t('Name') }}
                </p>
                <p
                  :class="{ active2: toggleSearch === 'Description' }"
                  @click="showDescription(), toggleSearch = 'Description'"
                >
                  {{ $t('Description') }}
                </p>
              </div>
            </div>
            <b-form-group
              id="input-select-1"
              label-for="select-1"
            >
              <vue-select
                v-if="item.measurementType === 'Meter' && toggleSearch === 'Description'"
                v-model="item.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByMeter : getFilterVariantsByCodeLike"
                :placeholder="$t('Description')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchMDesc"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageM == 1"
                    @click="prevMeter"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                    @click="nextMeter"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Meter' && toggleSearch === 'MaterialName'"
                v-model="item.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByMeter : getMaterialVariantColorNameLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchM"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageM == 1"
                    @click="prevMeter"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                    @click="nextMeter"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Package' && toggleSearch === 'MaterialName'"
                v-model="item.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPackage : getMaterialVariantColorNameLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchPack"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pagePack == 1"
                    @click="prevPackage"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                    @click="nextPackage"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Package' && toggleSearch === 'Description'"
                v-model="item.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPackage : getFilterVariantsByCodeLike"
                :placeholder="$t('Description')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchPackDesc"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pagePack == 1"
                    @click="prevPackage"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                    @click="nextPackage"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Set' && toggleSearch === 'MaterialName'"
                v-model="item.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVBySet : getMaterialVariantColorNameLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchSet"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageSet == 1"
                    @click="prevSet"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                    @click="nextSet"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Set' && toggleSearch === 'Description'"
                v-model="item.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVBySet : getFilterVariantsByCodeLike"
                :placeholder="$t('Description')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchSetDesc"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageSet == 1"
                    @click="prevSet"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                    @click="nextSet"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Pair' && toggleSearch === 'MaterialName'"
                v-model="item.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPair : getMaterialVariantColorNameLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchPair"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pagePair == 1"
                    @click="prevPair"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                    @click="nextPair"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Pair' && toggleSearch === 'Description'"
                v-model="item.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPair : getFilterVariantsByCodeLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchPairDesc"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pagePair == 1"
                    @click="prevPair"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                    @click="nextPair"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Verse' && toggleSearch === 'MaterialName'"
                v-model="item.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByVerse : getMaterialVariantColorNameLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchVerse"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageVerse == 1"
                    @click="prevVerse"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                    @click="nextVerse"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Verse' && toggleSearch === 'Description'"
                v-model="item.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByVerse : getFilterVariantsByCodeLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchVerseDesc"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageVerse == 1"
                    @click="prevVerse"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                    @click="nextVerse"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Part' && toggleSearch === 'MaterialName'"
                v-model="item.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPart : getMaterialVariantColorNameLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchP"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageP == 1"
                    @click="prevPart"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    type="button"
                    :style="getButtonStylePart"
                    :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                    @click="nextPart"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="item.measurementType === 'Part' && toggleSearch === 'Description'"
                v-model="item.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPart : getFilterVariantsByCodeLike"
                :placeholder="$t('MaterialVariant')"
                :reduce="(e) => e"
                label="materialVariantName"
                @search="onSearchPDesc"
                @input="currentStock(item.name.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageP == 1"
                    @click="prevPart"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    type="button"
                    :style="getButtonStylePart"
                    :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                    @click="nextPart"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
            </b-form-group>
            <b-form-group
              v-if="item.name"
              :label="`${$t('Price')}:`"
              style="width: 100%;"
            >
              <b-input
                v-model="item.name.pricePerMeter"
                type="number"
              />
            </b-form-group>
            <b-form-group
              v-if="item.measurementType === 'Meter'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="item.length"
                type="number"
                @input="valid(item,index)"
              />
              <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ item.name ? `${'Price'}: ${totalPriceCalc(item.name.pricePerMeter, item.length)}€` : null }}
              </p>
              <p
                v-if="item.length < 0.0001 "
                style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>
            <b-form-group
              v-else-if="item.measurementType === 'Package'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="item.length"
                type="number"
                @input="valid(item,index)"
              />
              <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ item.name ? `${'Price'}: ${totalPriceCalc(item.name.pricePerMeter, item.length)}€` : null }}
              </p>
              <p
                v-if="item.length < 0.0001 "
                style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>
            <b-form-group
              v-else-if="item.measurementType === 'Set'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="item.length"
                type="number"
                @input="valid(item,index)"
              />
              <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ item.name ? `${'Price'}: ${totalPriceCalc(item.name.pricePerMeter, item.length)}€` : null }}
              </p>
              <p
                v-if="item.length < 0.0001 "
                style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>
            <b-form-group
              v-else-if="item.measurementType === 'Pair'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="item.length"
                type="number"
                @input="valid(item,index)"
              />
              <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ item.name ? `${'Price'}: ${totalPriceCalc(item.name.pricePerMeter, item.length)}€` : null }}
              </p>
              <p
                v-if="item.length < 0.0001 "
                style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>
            <b-form-group
              v-else-if="item.measurementType === 'Verse'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="item.length"
                type="number"
                @input="valid(item,index)"
              />
              <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ item.name ? `${'Price'}: ${totalPriceCalc(item.name.pricePerMeter, item.length)}€` : null }}
              </p>
              <p
                v-if="item.length < 0.0001 "
                style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>
            <b-form-group
              v-else
              :label="`${$t('Part')}:`"
            >
              <b-input
                v-model="item.quantity"
                type="number"
                @input="valid(item,index)"
              />
              <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ item.name ? `${'Price'}: ${totalPriceCalc(item.name.pricePerMeter, item.quantity)}€` : null }}
              </p>
              <p
                v-if="item.quantity < 0.0001 "
                style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>
            <b-form-group
              v-if="item.name ? item.name.materialVariantId === clickedMaterialVariantId : false"
              :label="`${$t('Stock')}:`"
              style="width: 100%;"
            >
              <b-form-input
                v-model="getSavedStock.stockValue"
                type="number"
                disabled
              />
            </b-form-group>
          </div>
          <b-button
            type="button"
            size="sm"
            class="buttonSubmit"
            style="margin: 20px 0;"
            @click="addNewRowForCD()"
          >
            <p style="margin: 0">
              + {{ $t('AddMaterial') }}
            </p>
          </b-button>
        </div>
      </b-collapse>
    </div>
    <p
      v-if="totalCost != 0.00"
      style="font-weight: bold;"
    >
      {{ $t('TotalCost') }}: {{ totalCost }}€
    </p>
    <div style="display: flex; flex-direction: row;">
      <div style="display: flex; flex-direction: column; width: 28%">
        <b-form-group
          :label="`${$t('PickupDate')}`"
          style="display: inline-block; border-radius: 8px; padding: 8px; color: black;"
        >
          <b-input
            v-model="dress.pickUpDate"
            type="date"
            required
          />
        </b-form-group>
        <p
          v-if="dress.filledPickUpDate == false"
          style="color: red; margin: 0;"
        >
          {{ $t('PickUpRequired') }}
        </p>
      </div>
      <div
        style="display: flex; flex-direction: row; flex-wrap: wrap"
      >
        <div
          v-for="(probeDate, index) in dress.probeDates"
          :key="index"
          style="display: flex; flex-direction: column;"
        >
          <b-form-group
            style="display: inline-block; border-radius: 7px; padding: 8px; color: black;margin-left: 0px;"
          >
            <div style="display: flex; justify-content: space-between;">
              <h1
                v-if="!editProbeName"
                style="font-size: 17px; font-weight: 400;"
              >
                {{ probeDate.probeName }}
              </h1>
              <b-input
                v-else
                v-model="probeDate.probeName"
              />
              <div
                class="inside-actions"
                @click="editProbeName = !editProbeName"
              >
                <b-icon-pencil v-if="editProbeName == false" />
                <b-icon-check2 v-if="editProbeName == true" />
              </div>
            </div>
            <div style="display: flex;">
              <b-input
                v-model="probeDate.dateOfProbe"
                type="date"
                required
              />
              <div
                style="height:35px; width: 40px !important; background-color: white; border-radius: 10px; display: flex; justify-content: center; align-items: center; cursor: pointer; margin-left: 0px;"
                @click="removeProbeDate(index)"
              >
                <b-icon-trash
                  style="width: auto; height: 20px; color: black; "
                />
              </div>
            </div>
            <b-input
              v-model="probeDate.notes"
              :placeholder="$t('Notes')"
              style="margin-top:5px"
            />
          </b-form-group>
          <p
            v-if="isSubmitDisabled && submitClicked"
            style="color: red;margin: 5px;"
          >
            {{ $t('FittingDayReq') }}
          </p>
        </div>
        <b-button
          type="button"
          size="sm"
          class="button"
          style="margin-top: 40px; width: 280px"
          @click="addNewDate"
        >
          <p style="margin: 0">
            + {{ $t('Add Fitting Date') }}
          </p>
        </b-button>
      </div>
    </div>

    <div style="display:flex;">
      <div style="display:flex;flex-direction: column; width: 28%;">
        <b-form-group
          :label="`${$t('Price')}:`"
          style="display: inline-block; border-radius: 7px; padding: 8px; color: black; margin-top: 10px; width: 100%;"
        >
          <div style="display: flex; justify-content: flex-start; align-items: center;flex-direction: column;">
            <div style="display: flex;">
              <b-input
                v-model.number="dress.price"
                type="number"
                style="width: 90%; margin-right: 5px;"
              />
              <b-form-select
                v-model="dress.currency"
                dropup
                required
                :options="currencies"
                :aria-placeholder="$t('SelectOrderType')"
                aria-describedby="input-1-live-feedback"
                style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
              />
            </div>
          </div>
          <p
            v-if="discountTypee == '%' && (dress.currency != '$' && dress.currency != '€' && dress.currency != 'CHF') "
            style="margin-bottom:0px"
          >
            {{ $t('SelectValute') }}
          </p>
        </b-form-group>
        <b-form-group
          :label="$t('PaidPrice')"
          style="display: inline-block; border-radius: 7px; padding: 8px; color: black; margin: 10px 0px 0px 0px; width: 100%;"
        >
          <div style="display: flex;flex-direction:column; justify-content: flex-start; align-items: center; width: 100%">
            <div style="display: flex;">
              <b-input
                v-model.number="dress.paidPrice"
                type="number"
                style="width: 100%; margin-right: 5px;"
              />
              <b-form-select
                v-model="dress.currency"
                dropup
                required
                :options="currencies"
                :aria-placeholder="$t('SelectOrderType')"
                aria-describedby="input-1-live-feedback"
                style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
              />
            </div>
            <div style="width: 100%">
              <b-form-group
                :label="`${$t('Notes')}:`"
              >
                <b-input
                  v-model.number="dress.priceNote"
                  style="width: 100%; margin-right: 5px;"
                />
              </b-form-group>
            </div>
          </div>

          <p
            v-if="discountTypee == '%' && (dress.currency != '$' && dress.currency != '€' && dress.currency != 'CHF') "
            style="margin-bottom:0px"
          >
            {{ $t('SelectValute') }}
          </p>
        </b-form-group>
        <div style="margin: 5%; display: flex">
          <p class="p-0 m-0 ml-50">
            {{ $t('IncludeTax') }}
            <toggle-button
              :value="includeTax"
              :sync="true"
              color="#242F6E"
              @change="includeTax = !includeTax"
            />
          </p>
          <div
            v-if="includeTax == true"
            style=" margin-left: 10px; margin-bottom: 10px"
          >
            <span>18%</span>
          </div>
        </div>
      </div>
      <div style="display:flex;flex-direction: row;justify-content:flex-start; height: 1%;">
        <b-button
          v-if="!showDiscountForm"
          type="button"
          size="sm"
          class="button"
          style="margin: 20px 20px 20px 0px; width: 280px; margin-top: 16.5%"
          @click="showDiscountForm = true"
        >
          <p style="margin: 0">
            + {{ $t('AddDiscount') }}
          </p>
        </b-button>
        <b-form-group
          v-if="showDiscountForm"

          :label="$t('Discount')"
          style="width:30%;display: inline-block; border-radius: 7px; padding: 8px; color: black; margin-top: 10px; width: 35%;"
        >
          <div style="display:flex;">
            <b-input
              v-model.number="dress.discount"
              type="number"
              default="0"
              style="width: 60%;;margin-right: 5px;"
            />
            <b-form-select
              v-model="discountTypee"
              style="width: 20%; margin-right: 5px;height:40px;border-radius:5px; border: 1px solid #ced4da;"
            >
              <option value="$">
                $
              </option>
              <option value="€">
                €
              </option>
              <option value="CHF">
                CHF
              </option>
              <option value="%">
                %
              </option>
            </b-form-select>
            <div>
              <b-icon-trash
                type="button"
                size="sm"
                style="background:white;color: black; height: 35px; width: 30px; padding: 5px; border-radius: 10px; margin-bottom: 0px;"
                @click="showDiscountForm = false,resetDiscountedPrice() "
              />
            </div>
          </div>
        </b-form-group>
        <b-form-group
          v-if="showDiscountForm"
          :label="`${$t('PriceDiscount')}:`"
          style="width:30%;display: inline-block; border-radius: 7px; padding: 8px; color: black; margin: 10px 0px 0px 12px; width: 35%;"
        >
          <div style="display:flex">
            <b-input
              v-model.number="discountedPrice"
              type="number"
              style="width: 90%; margin-right: 5px;"
            />
            <b-form-select
              v-model="dress.currency"
              dropup
              required
              :options="currencies"
              :aria-placeholder="$t('SelectOrderType')"
              aria-describedby="input-1-live-feedback"
              style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
            />
          </div>
          <p
            v-if="discountTypee == '%' && (dress.currency != '$' && dress.currency != '€' && dress.currency != 'CHF') "
            style="margin-bottom:0px"
          >
            {{ $t('SelectValute') }}
          </p>
        </b-form-group>
      </div>
    </div>
    <vue-easy-lightbox
      :esc-disabled="false"
      :visible="image.visible"
      :imgs="image.imgs"
      :index="image.index"
      @hide="handleHide"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueEasyLightbox from 'vue-easy-lightbox'
import { client } from '../domainConfig'


export default {
  components: {
    VueEasyLightbox,

  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['dress', 'idx', 'submitClicked', 'measerments', 'selectedValue', 'clientId', 'body', 'showDressError'],
  data() {
    return {
      toggleSearch: 'MaterialName',
      clickedMaterialVariantId: null,
      includeTax: false,
      taxRate: 0,
      selectedMaterialVariantPackage: '',
      selectedMaterialVariant: '',
      selectedMaterialVariantPart: '',
      selectedMaterialVariantSet: '',
      selectedMaterialVariantPair: '',
      selectedMaterialVariantVerse: '',
      showDiscountForm: false,
      discountTypee: '€',
      client: client.clientName,
      currencies: ['€', '$', 'CHF'],
      index: 1,
      pageM: 1,
      pageP: 1,
      pagePack: 1,
      pageSet: 1,
      pagePair: 1,
      pageVerse: 1,
      editProbeName: false,
      image: {
        zoom: 1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, //
      },
      pageSize: 15,
      isUndefined: false,
      radio: null,
      sizeOptions: [
        {
          size: 'xs',
        },
        {
          size: 's',
        },
        {
          size: 'm',
        },
        {
          size: 'l',
        },
        {
          size: 'xl',
        },
      ],
      radioStates: {
        thorax: false,
        aroundBust: false,
        aroundUnderBust: false,
        waist: false,
        aroundHips: false,
        aroundLeg: false,
        aroundKnee: false,
        ankle: false,
        breastReduction: false,
        chestDistance: false,
        interBust: false,
        frontBody: false,
        length1: false,
        length2: false,
        length3: false,
        length4: false,
        aroundSleeve: false,
        elbow: false,
        wrist: false,
        aroundNeck: false,
        backBody: false,
        shoulders: false,
        back: false,
        declineOfTheHips: false,
        sleeveLength: false,
      },
    }
  },

  computed: {
    ...mapGetters(['getTotalItemsPages', 'getMaterialVariantColorNameLike', 'getImagesProduct', 'getMaterialsVByMeter', 'getSizeTypes',
      'getMaterialsVByPart', 'getFilteredMV', 'getTotalPagesForMVP',
      'getTotalPagesForMVM', 'getCurrentPageForMVM', 'getCurrentPageForMVP', 'getSavedStock', 'getProductSize',
      'getMeasermentsById', 'getMaterialsVByPackage', 'getMaterialsVBySet', 'getMaterialsVByVerse', 'getMaterialsVByPair', 'getFilterVariantsByCodeLike']),
    isMaterialVariantIdEmpty() {
      return this.dress.formItem.some((item) => { return item.name === '' });
    },
    getButtonStyle() {
      if (this.selectedMaterialVariant !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVM
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
    getButtonStylePart() {
      if (this.selectedMaterialVariantPart !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVP
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
    totalCost() {
    // Initialize a variable to store the total cost
      let total = 0;

      // Iterate through each item in dress.formItem
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.dress.formItem) {
        if (item.name) {
        // Check if the item has a name (material variant selected)
          if (item.measurementType === 'Meter') {
          // For items measured in meters, calculate the total cost
            total += item.name.pricePerMeter * item.length;
          } else if (item.measurementType === 'Package') {
          // For items measured in meters, calculate the total cost
            total += item.name.pricePerMeter * item.length;
          } else if (item.measurementType === 'Set') {
          // For items measured in meters, calculate the total cost
            total += item.name.pricePerMeter * item.length;
          } else if (item.measurementType === 'Pair') {
          // For items measured in meters, calculate the total cost
            total += item.name.pricePerMeter * item.length;
          } else if (item.measurementType === 'Verse') {
          // For items measured in meters, calculate the total cost
            total += item.name.pricePerMeter * item.length;
          } else {
          // For items measured in parts, calculate the total cost
            total += item.name.pricePerMeter * item.quantity;
          }
        }
      }

      // Return the total cost with 2 decimal places and in the correct format
      return `${total.toFixed(2)}`;
    },
    isMaterialVariantIdFilled() {
      return this.dress.formItem.every((item) => { return item.name.materialVariantId != '' || item.name == null });
    },
    filteredSizeTypes() {
      return this.getProductSize.filter(type => { return type.name !== 'ALL' });
    },
    isSubmitDisabled() {
      // Check if any "dateOfProbe" field is empty
      return this.dress.probeDates.some((probeDate) => { return !probeDate.dateOfProbe });
    },
    // discountedPrice: {
    //   get() {
    //     return this.dress.price - this.dress.discount;
    //   },
    //   set(value) {
    //     this.dress.discount = this.dress.price - value;
    //     this.dress.priceDiscount = value;
    //   },
    // },
    discountedPrice: {
      get() {
        if (this.discountTypee === '$' || this.discountTypee === '€' || this.discountTypee === 'CHF') {
          return this.dress.price - this.dress.discount;
        } if (this.discountTypee === '%') {
          if (this.dress.price === 0) {
            return 0; // Avoid division by zero
          }
          const percentageDiscount = (this.dress.discount / 100) * this.dress.price;
          return this.dress.price - percentageDiscount;
        }

        // Default to 0 when no discount type is selected
        return 0;
      },
      set(value) {
        // You can add validation here if needed
        if (this.discountTypee === '$' || this.discountTypee === '€' || this.discountTypee === 'CHF') {
          this.dress.discount = this.dress.price - value;
        } else if (this.discountTypee === '%') {
          if (this.dress.price === 0) {
            this.dress.discount = 0; // Avoid division by zero
          } else {
            const percentageDiscount = (this.dress.price - value) / (this.dress.price * 100);
            this.dress.discount = percentageDiscount;
          }
        }
      },
    },
  },

  watch: {
    includeTax(newIncludeTax) {
      this.taxRate = newIncludeTax ? 18 : 0;
      this.$emit('taxRatee', this.taxRate);
    },
    isMaterialVariantIdEmpty(newValue) {
      this.dress.isEmpty = newValue;
    },
    isMaterialVariantIdFilled(newValue) {
      this.dress.isEmpty = !newValue;
    },
    isSubmitDisabled(value) {
      this.$emit('fitting', value)
    },
    showDiscountForm(newShowDiscountFormValue) {
      // if (newShowDiscountFormValue) {
      //   this.dress.itemi.discount = 0;
      // }
      this.$emit('DiscountValue', newShowDiscountFormValue)
    },
    // eslint-disable-next-line func-names
    'body.bodyMeasurementDto': function (value) {
      this.dress.sizesDto.thorax = value.thorax
      this.dress.sizesDto.aroundBust = value.aroundBust
      this.dress.sizesDto.aroundUnderBust = value.aroundUnderBust
      this.dress.sizesDto.waist = value.waist
      this.dress.sizesDto.aroundHips = value.aroundHips
      this.dress.sizesDto.aroundLeg = value.aroundLeg
      this.dress.sizesDto.aroundKnee = value.aroundKnee
      this.dress.sizesDto.aroundNeck = value.aroundNeck
      this.dress.sizesDto.aroundSleeve = value.aroundSleeve
      this.dress.sizesDto.ankle = value.ankle
      this.dress.sizesDto.breastReduction = value.breastReduction
      this.dress.sizesDto.chestDistance = value.chestDistance
      this.dress.sizesDto.interBust = value.interBust
      this.dress.sizesDto.frontBody = value.frontBody
      this.dress.sizesDto.length1 = value.length1
      this.dress.sizesDto.length2 = value.length2
      this.dress.sizesDto.length3 = value.length3
      this.dress.sizesDto.length4 = value.length4
      this.dress.sizesDto.elbow = value.elbow
      this.dress.sizesDto.wrist = value.wrist
      this.dress.sizesDto.back = value.back
      this.dress.sizesDto.backBody = value.backBody
      this.dress.sizesDto.shoulders = value.shoulders
      this.dress.sizesDto.declineOfTheHips = value.declineOfTheHips
      this.dress.sizesDto.sleeveLength = value.sleeveLength
    },
    // eslint-disable-next-line
        'dress.sizesDto.thorax': function (newVal) {
      this.radioStates.thorax = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
        'dress.sizesDto.aroundBust': function (newVal) {
      this.radioStates.aroundBust = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
        'dress.sizesDto.aroundUnderBust': function (newVal) {
      this.radioStates.aroundUnderBust = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.waist': function (newVal) {
      this.radioStates.waist = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.aroundHips': function (newVal) {
      this.radioStates.aroundHips = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.aroundLeg': function (newVal) {
      this.radioStates.aroundLeg = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.aroundKnee': function (newVal) {
      this.radioStates.aroundKnee = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.ankle': function (newVal) {
      this.radioStates.ankle = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.breastReduction': function (newVal) {
      this.radioStates.breastReduction = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.chestDistance': function (newVal) {
      this.radioStates.chestDistance = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.frontBody': function (newVal) {
      this.radioStates.frontBody = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.length1': function (newVal) {
      this.radioStates.length1 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.length2': function (newVal) {
      this.radioStates.length2 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.length3': function (newVal) {
      this.radioStates.length3 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.length4': function (newVal) {
      this.radioStates.length4 = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.aroundSleeve': function (newVal) {
      this.radioStates.aroundSleeve = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.elbow': function (newVal) {
      this.radioStates.elbow = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.wrist': function (newVal) {
      this.radioStates.wrist = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.aroundNeck': function (newVal) {
      this.radioStates.aroundNeck = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.backBody': function (newVal) {
      this.radioStates.backBody = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.shoulders': function (newVal) {
      this.radioStates.shoulders = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.back': function (newVal) {
      this.radioStates.back = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.declineOfTheHips': function (newVal) {
      this.radioStates.declineOfTheHips = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line
    'dress.sizesDto.sleeveLength': function (newVal) {
      this.radioStates.sleeveLength = newVal !== '' && newVal !== null;
    },
    // eslint-disable-next-line func-names
    'dress.price': function (newPrice) {
      if (newPrice < this.dress.discount) {
        this.dress.discount = 0;
      }
    },
    // eslint-disable-next-line func-names
    'dress.discount': function (newDiscount) {
      if (this.discountTypee === '$' || this.discountTypee === '€' || this.discountTypee === 'CHF') {
        if (newDiscount > this.dress.price && newDiscount > this.discountedPrice) {
          this.dress.discount = 0;
        }
      } else if (this.discountTypee === '%') {
        if (newDiscount > 100) {
          this.dress.discount = 0;
        }
      }
    },
    // eslint-disable-next-line func-names
    'dress.currency': function (newCurrency) {
      if ((newCurrency === '$' || newCurrency === '€' || newCurrency === 'CHF') && this.discountTypee != '%') {
        this.discountTypee = newCurrency
      }
    },
    discountedPrice(newDiscountedPrice) {
      if (newDiscountedPrice > this.dress.price) {
        this.dress.price = newDiscountedPrice;
        this.dress.discount = 0;
      }
    },
    discountTypee(value) {
      if (value === '$' || value === '€' || value === 'CHF') {
        this.dress.currency = value
      } else if (value === '%') {
        this.dress.currency = '€'
      }
      this.dress.discount = 0
      this.$emit('discount-typee', value)
    },
    // submitClicked(value) {
    //   if (value == true) {
    //     if (this.dress.standardSize == '' || this.dress.standardSize == null) {
    //       this.showDressError = true
    //     } else {
    //       this.showDressError = false
    //     }
    //   }
    // },
    dress: {
      handler(newValue) {
        if (this.submitClicked == true) {
          if (newValue.pickUpDate == '') {
            this.dress.filledPickUpDate = false
          } else {
            this.dress.filledPickUpDate = true
          }
        }
        if (newValue.existingSizes == true) {
          if (newValue.standardSize == '' || newValue.standardSize == null) {
            this.dress.showDressError = true
          } else {
            this.dress.showDressError = false
          }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const item of this.dress.formItem) {
          if (item.name.materialVariantId == undefined) {
            this.isUndefined = true
            return;
          // eslint-disable-next-line no-else-return
          } else {
            this.isUndefined = false
          }
        }
        if (this.isUndefined == false) {
          // eslint-disable-next-line arrow-body-style, arrow-parens
          const uniqueValues = new Set(newValue.formItem.map(v => v.name.materialVariantId))
          if (uniqueValues.size < newValue.formItem.length) {
            this.dress.duplicateMV = true
          } else {
            this.dress.duplicateMV = false
          }
        }
        if (newValue) {
          if (newValue.discount === undefined || newValue.discount === '' || newValue.discount === null) {
            this.$set(newValue, 'discount', 0);
          }
        }
        if (newValue) {
          if (newValue.currency === undefined || newValue.currency === '' || newValue.currency === null) {
            this.$set(newValue, 'currency', '€');
          }
        }
      },
      deep: true,
    },
    // measurement(value) {
    //   console.log('vvaa', value)
    // },
  },

  mounted() {
    if (this.dress.discount > 0) {
      this.showDiscountForm = true
    }
    // this.dress.currency = this.discountTypee;
    this.loadSizeTypes();
    // console.log('123', this.getMeasermentsById)
    // console.log('Selected value:', this.selectedValue);
    // console.log('1234', this.dress.sizesDto)
    this.dress.sizesDto.thorax = this.body.bodyMeasurementDto.thorax
    this.dress.sizesDto.aroundBust = this.body.bodyMeasurementDto.aroundBust
    this.dress.sizesDto.aroundUnderBust = this.body.bodyMeasurementDto.aroundUnderBust
    this.dress.sizesDto.waist = this.body.bodyMeasurementDto.waist
    this.dress.sizesDto.aroundHips = this.body.bodyMeasurementDto.aroundHips
    this.dress.sizesDto.aroundLeg = this.body.bodyMeasurementDto.aroundLeg
    this.dress.sizesDto.aroundKnee = this.body.bodyMeasurementDto.aroundKnee
    this.dress.sizesDto.aroundNeck = this.body.bodyMeasurementDto.aroundNeck
    this.dress.sizesDto.aroundSleeve = this.body.bodyMeasurementDto.aroundSleeve
    this.dress.sizesDto.ankle = this.body.bodyMeasurementDto.ankle
    this.dress.sizesDto.breastReduction = this.body.bodyMeasurementDto.breastReduction
    this.dress.sizesDto.chestDistance = this.body.bodyMeasurementDto.chestDistance
    this.dress.sizesDto.interBust = this.body.bodyMeasurementDto.interBust
    this.dress.sizesDto.frontBody = this.body.bodyMeasurementDto.frontBody
    this.dress.sizesDto.length1 = this.body.bodyMeasurementDto.length1
    this.dress.sizesDto.length2 = this.body.bodyMeasurementDto.length2
    this.dress.sizesDto.length3 = this.body.bodyMeasurementDto.length3
    this.dress.sizesDto.length4 = this.body.bodyMeasurementDto.length4
    this.dress.sizesDto.elbow = this.body.bodyMeasurementDto.elbow
    this.dress.sizesDto.wrist = this.body.bodyMeasurementDto.wrist
    this.dress.sizesDto.back = this.body.bodyMeasurementDto.back
    this.dress.sizesDto.backBody = this.body.bodyMeasurementDto.backBody
    this.dress.sizesDto.shoulders = this.body.bodyMeasurementDto.shoulders
    this.dress.sizesDto.declineOfTheHips = this.body.bodyMeasurementDto.declineOfTheHips
    this.dress.sizesDto.sleeveLength = this.body.bodyMeasurementDto.sleeveLength
  },

  methods: {
    ...mapActions(['filterVariantByColor',
      'currentStockByVariant',
      'getImagesByMaterialVariantId',
      'getMaterialVariantsToMDCPagination',
      'loadSizeTypes',
      'testResetMV',
      'resetAllFiltered',
      'loadMaterialVaraintsCategoryNameLike',
      'filterVariantByCode']),
    addNewRowForCD() {
      this.dress.formItem.push({
        materialVariantId: '',
        measurementType: 'Meter',
        name: '',
        length: 0,
        quantity: 0,
      });
    },
    showMaterialName() {
      this.toggleSearch = 'MaterialName'
    },
    showDescription() {
      this.toggleSearch = 'Description'
    },
    currentStock(value) {
      this.clickedMaterialVariantId = value
      this.currentStockByVariant(value)
    },
    totalPriceCalc(pricePerMeter, length) {
      return (pricePerMeter * length).toFixed(2);
    },
    resetDiscountedPrice() {
      this.discountedPrice = this.dress.price;
    },
    valid(item) {
      console.log(item)
      if (item.measurementType == 'Meter') {
        item.quantity = 0
        if (item.length < 0.001) {
          this.dress.showLengthError = true;
        } else {
          this.dress.showLengthError = false;
        }
      } else if (item.measurementType == 'Parts') {
        item.length = 0
        if (item.quantity < 0.001) {
          this.dress.showLengthError = true;
        } else {
          this.dress.showLengthError = false;
        }
      }
    },
    resetMeter(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
    },
    resetPart(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
    },
    resetPackage(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Package',
      })
    },
    resetSet(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Set',
      })
    },
    resetPair(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Pair',
      })
    },
    resetVerse(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Verse',
      })
    },
    removeProbeDate(index) {
      this.dress.probeDates.splice(index, 1);
    },
    async getAllImages(item) {
      this.$store.commit('SET_IMAGES_PRODUCTION_MANAGEMENT', [{
        fileDataId: 'aaf153a4-be48-ee11-abf3-124abb807f69',
        mainDressColorId: 'e2b146ea-a7e9-4584-ee19-08dba4a55447',
        url: '',
        fileName: '1693569414176-createordermodal.png',
      }]);
      try {
        // this.changeLoadingtoTrue(false)

        await this.getImagesByMaterialVariantId(item).then((res) => {
          console.log(res);
          // this.changeLoadingtoTrue(true)
        })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
      this.image.imgs = []
      for (let i = 0; i < this.getImagesProduct.length; i++) {
        const image = this.getImagesProduct[i]
        this.image.imgs.push({
          src: image.url,
          title: image.fileName,
          materialVariantPhotoId: image.materialVariantPhotoId,
          fileName: image.fileName,
          fileType: image.fileType,
        })
      }
      this.show()
    },
    show() {
      this.image.visible = true
    },
    // validim(item) {
    //   if (item == null) {
    //     this.dress.showDressError = true;
    //   } else {
    //     this.dress.showDressError = false;
    //   }
    // },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = []
    },
    addNewDate() {
      this.dress.probeDates.push(
        {
          probeName: `${this.$t('FitDate')} ${this.dress.probeDates.length + 1}`,
          dateOfProbe: '',
          notes: null,
        },
      );
    },
    updateSelectedValue() {
      this.$emit('selected-value-change', this.idx, this.selectedValue);
    },
    removeMaterialItemFromCD(index) {
      this.dress.formItem.splice(index, 1)
    },
    removeCustomDress(index) {
      this.$emit('sendIndex', index)
    },
    async getMVbyMeter(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Meter',
      });
    },
    async getMVbyPackage(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Package',
      });
    },
    async getMVbySet(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Set',
      });
    },
    async getMVbyVerse(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Verse',
      });
    },
    async getMVbyPair(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Pair',
      });
    },
    async getMVbyPart(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Part',
      });
    },
    nextMeter() {
      if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant == '') {
        this.pageM += 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant != '') {
        this.pageM += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    prevMeter() {
      if (this.pageM > 1 && this.selectedMaterialVariant == '') {
        this.pageM -= 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM > 1 && this.selectedMaterialVariant != '') {
        this.pageM -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    nextPackage() {
      if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage == '') {
        this.pagePackage += 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage != '') {
        this.pagePackage += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    prevPackage() {
      if (this.pagePackage > 1 && this.selectedMaterialVariantPackage == '') {
        this.pagePackage -= 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage > 1 && this.selectedMaterialVariantPackage != '') {
        this.pagePackage -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    nextSet() {
      if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet == '') {
        this.pageSet += 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet != '') {
        this.pageSet += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    prevSet() {
      if (this.pageSet > 1 && this.selectedMaterialVariantSet == '') {
        this.pageSet -= 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet > 1 && this.selectedMaterialVariantSet != '') {
        this.pageSet -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    nextVerse() {
      if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse == '') {
        this.pageVerse += 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse != '') {
        this.pageVerse += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    prevVerse() {
      if (this.pageVerse > 1 && this.selectedMaterialVariantVerse == '') {
        this.pageVerse -= 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse > 1 && this.selectedMaterialVariantVerse != '') {
        this.pageVerse -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    nextPair() {
      if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair == '') {
        this.pagePair += 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair != '') {
        this.pagePair += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    prevPair() {
      if (this.pagePair > 1 && this.selectedMaterialVariantPair == '') {
        this.pagePair -= 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair > 1 && this.selectedMaterialVariantPair != '') {
        this.pagePair -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    nextPart() {
      if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariantPart == '') {
        this.pageP += 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariantPart != '') {
        this.pageP += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPart,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    prevPart() {
      if (this.pageP > 1 && this.selectedMaterialVariantPart == '') {
        this.pageP -= 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP > 1 && this.selectedMaterialVariantPart == '') {
        this.pageP -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPart,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    async onSearchM(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariant = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchMDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariant = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPack(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPackDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchSet(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchSetDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPair(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPairDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchVerse(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchVerseDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          })
        }
      }, 500);
    },

    async onSearchP(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchPDesc(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          })
        }
      }, 500);
    },
  },
}
</script>

<style scoped lang="scss">
.active2 {
  font-weight: bold;
  color: $base-color;
}
.search-toggle {
  margin: 5px 0px;
}
.pink-radio {
  margin-left: 6px;
  margin-top: 9px;
}
.right {
  margin: 9px 6px 0px 0px !important;
}
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.headline{
  font-size: 20px;
  font-weight: 600;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.fixed-height-modal {
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding-right: 1rem;
}

.customDress-container {
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}

.customDress-container:last-child {
  margin-bottom: 20px;
}

.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 0px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #FF274F;
  color: #fff;
}

.f {
  border-color: red !important;
}
.number {
    margin-top: 8px;
    padding-right: 5px;
  }
.front-body {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-bottom: 20px;
  img {
    width: 100%;
  }
  .betweenCol{
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .input{
    max-width: 100%;
    height: 51px;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .toraks {
    display: flex;
    position: absolute;
    top: 80px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .rrethiGjoksit {
    display: flex;
    position: absolute;
    top: 125px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .rrethiNenGjoksit {
    display: flex;
    position: absolute;
    top: 170px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .beli {
    display: flex;
    position: absolute;
    top: 215px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .vithet {
    display: flex;
    position: absolute;
    top: 263px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .rrethiKembes {
    display: flex;
    position: absolute;
    bottom: 222px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .rrethiGjurit {
    display: flex;
    position: absolute;
    bottom: 158px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .zoguKembeve {
    display: flex;
    position: absolute;
    bottom: 48px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .reniaGjoksit {
    display: flex;
    position: absolute;
    top: 65px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .nderGjoks {
    display: flex;
    position: absolute;
    top: 158px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .trupiPara {
    display: flex;
    position: absolute;
    top: 200px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .distancaGjoksit {
    display: flex;
    position: absolute;
    top: 110px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .gjatesia1 {
    display: flex;
    position: absolute;
    bottom: 240px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .gjatesia2 {
    display: flex;
    position: absolute;
    bottom: 145px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .gjatesia3 {
    display: flex;
    position: absolute;
    bottom: 80px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .gjatesia4 {
    display: flex;
    position: absolute;
    bottom: 20px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
}
.btn-con {
  display: flex;
  justify-content: flex-end;
  button {
    border: none;
    background: #FF274F;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}
.back-body {
  position: relative;
  img {
    width: 100%;
  }
  .rrethiMenges {
    display: flex;
    position: absolute;
    top: 122px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .brryli {
    display: flex;
    position: absolute;
    top: 166px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .kyçi {
    display: flex;
    position: absolute;
    top: 192px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .rrethiQafes {
    display: flex;
    position: absolute;
    top: 30px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .trupiPrapa {
    display: flex;
    position: absolute;
    top: 211px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .supet {
    display: flex;
    position: absolute;
    top: 78px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .shpina {
    display: flex;
    position: absolute;
    top: 135px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .reniaVitheve {
    display: flex;
    position: absolute;
    bottom: 253px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .gjatesiaMenges {
    display: flex;
    position: absolute;
    top: 77px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
}
@media only screen and (max-width: 1200px){
  .front-body {
  position: relative;
  .toraks {
    top: 65px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjoksit {
    position: absolute;
    top: 95px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiNenGjoksit {
    position: absolute;
    top: 125px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .beli {
    position: absolute;
    top: 155px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .vithet {
    position: absolute;
    top:249px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiKembes {
    position: absolute;
    bottom: 65px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjurit {
    position: absolute;
    bottom: 35px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .zoguKembeve {
    position: absolute;
    bottom: 5px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .reniaGjoksit {
    position: absolute;
    top: 65px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .nderGjoks {
    position: absolute;
    top: 125px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .trupiPara {
    position: absolute;
    top: 155px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .distancaGjoksit {
    position: absolute;
    top: 95px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia1 {
    position: absolute;
    bottom: 95px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia2 {
    position: absolute;
    bottom: 65px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia3 {
    position: absolute;
    bottom: 35px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia4 {
    position: absolute;
    bottom: 5px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
}
.btn-con {
  display: flex;
  button {
    border: none;
    background-color: #6c757d;;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}
.back-body {
  position: relative;
  img {
    width: 100%;
  }
  .rrethiMenges {
    position: absolute;
    top: 126px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .brryli {
    position: absolute;
    top: 156px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .kyçi {
    position: absolute;
    top: 192px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiQafes {
    position: absolute;
    top: 70px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .trupiPrapa {
    position: absolute;
    top: 215px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .supet {
    position: absolute;
    top: 97px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .shpina {
    position: absolute;
    top: 70px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .reniaVitheve {
    position: absolute;
    bottom: 100px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesiaMenges {
    position: absolute;
    top: 98px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
}
}
input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -3px;
        left: -1px;
        position: relative;
        background-color: $base-color ;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
@media only screen and (max-width: 800px) {
  .front-body {
    .vithet {
      position: absolute;
    top: 195px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
    }
  }
}
</style>
