<template>
  <div>

    <div class="filtering">
      <div class="search-toggle">
        <p>{{ $t('SearchBy') }}</p>
        <div>
          <p
            :class="{ active2: !toggleSearch }"
            @click="showOrderNumber()"
          >
            {{ $t('OrderNumber') }}
          </p>
          <p
            :class="{ active2: toggleSearch }"
            @click="showArticleNumber()"
          >
            {{ $t('ArticleNumber') }}
          </p>
        </div>
      </div>
      <div v-if="showw == true">
        <div>
          <div class="black">
            <b-icon-search
              v-if="searchOrder == ''"
              class="search1"
              style=" position: absolute; left: 5px; top: 13px"
            />
            <b-icon-x-circle
              v-else-if="searchOrder != '' && !noSearch"
              class="search1"
              style="position: absolute; margin-top: -6px; right: 20px; top: 17px; cursor: pointer"
              @click="searchOrder = ''"
            />
            <input
              v-model="searchOrder"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('OrderNumber')"
              :style="getOrderArticleByArticleNumber.length > 0 && noSearch ? 'border-radius: 8px 8px 8px 8px' : ''"
              @keyup="inputChanged"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
            >
          </div>
          <div
            v-if="getOrderArticleByArticleNumber.length > 0 && noSearch"
            ref="scrollContainer"
            class="dropdrop"
            :style="getOrderArticleByArticleNumber.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getOrderArticleByArticleNumber"
              :key="index"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="searchByOn(result); noSearch = false"
            >
              <p style="margin: 0">
                {{ result.orderNumber }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showw == false">
        <div>
          <div class="black">
            <b-icon-search
              v-if="searchCN == ''"
              class="search1"
              style="position: absolute; left: 5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchCN != '' && !noSearch"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="searchCN = ''"
            />
            <input
              v-model="searchCN"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('ArticleNumber')"
              :style="getOrderArticleByArticleNumber.length > 0 && noSearchTwo ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged1"
              @keydown.down="onArrowDown1"
              @keydown.up="onArrowUp1"
            >
          </div>
          <div
            v-if="getOrderArticleByArticleNumber.length > 0 && noSearchTwo"
            ref="scrollContainer"
            class="dropdrop"
            style="
              display: inline-block;
              overflow: auto;
              position: absolute;
              background: white;
              width: 229px;
              box-shadow: none;
            "
            :style="getOrderArticleByArticleNumber.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getOrderArticleByArticleNumber"
              ref="options2"
              :key="index"
              :class="{ 'is-active': index === arrowCounter }"
              style="cursor: pointer"
              class="search-item"
              @click="searchByCn(result); noSearchTwo = false"
            >
              <p style="margin: 0">
                {{ result.articleNumber }} / {{ result.orderNumber }}
              </p>
            </div>
            <div
              v-if="getOrderArticleByArticleNumber.length == 0"
              class="search-item"
            >
              <p>{{ $t('NoResult') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style="width: 100%;  padding-top: 16px; margin-top: 15px; height: 80vh; overflow-y: scroll;"
      class="scroll"
    >
      <table
        class="team_table"
      >
        <thead style="position: sticky; top: -18px">
          <tr>
            <th>
              {{ $t('OrderNumber') }}
            </th>
            <th>
              {{ $t('ArticleNumber') }}
            </th>
            <th>
              {{ $t('SendToStock') }}
            </th>
            <th>
              {{ $t('History') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(items, index) in getOrderItemInProduction"
            :key="index"
          >
            <td>{{ items.orderNumber }}</td>
            <td>{{ items.articleNumber }}</td>
            <td>
              <button
                v-b-modal.send-to-stock
                class="button"
                @click="showLocations(items.orderNumber, items.orderItemId, items.articleNumber)"
              >
                {{ $t('SendToStock') }}
              </button>
            </td>
            <td><span
              v-b-toggle.history-of-articles-prod
              v-b-tooltip.hover
              :title="$t('TrackingArticle')"
              @click="trackOrder(items.orderNumber, items.orderItemId)"
            >                    <img
              src="@/assets//images/next.png"
              alt=""
              style="width: 30px;"
            ></span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <b-pagination
        v-if="getOrderItemInProductionTotalItems > 1"
        v-model="pageNumber"
        :total-rows="getOrderItemInProductionTotalItems"
        :per-page="pageSize"
        first-number
        last-number
        align="fill"
        prev-class="prev-itemi"
        next-class="next-itemi"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
        </template>
        <template #next-text>
          <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
        </template>
      </b-pagination>
    </div>
    <SendToStock
      v-if="tabIndex === 2"
      :order-item-id-state="orderItemIdState"
      :order-number-state="orderNumberState"
      :client-name-status="clientNameStatus"
      :article-number="articleNumber"
      @sendToStock="returnToStock"
    />
    <OrderItemTrackingProductManagement
      v-if="tabIndex === 2"
      :order-number="orderss"
      :order-item-id="orderid"
      @onCancelToggle="onCancelToggle"
    />
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SendToStock from '@/modals/SendToStock.vue'
import OrderItemTrackingProductManagement from '@/components/OrderItemTrackingProductManagement.vue'

export default {
  components: {
    SendToStock,
    OrderItemTrackingProductManagement,
  },
  props: ['tabIndex'],
  data() {
    return {
      isSidebarOpen: false,
      pageNumber: 1,
      pageSize: 15,
      orderNumber: null,
      orderItemIdState: null,
      articleNumber: null,
      toggleSearch: false,
      showHide: false,
      showw: true,
      searchCN: '',
      searchOrder: '',
      classNameCh: '',
      noSearch: false,
      noSearchTwo: false,
    }
  },
  computed: {
    ...mapGetters(['getOrderItemInProduction', 'getOrderItemInProductionTotalItems', 'getOrderArticleByArticleNumber']),
  },
  watch: {
    pageNumber(value) {
      this.orderItemsInProduction({ pageNumber: value, pageSize: this.pageSize })
    },
    searchOrder(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.noSearch = false
          this.orderItemsInProduction({
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            orderNumber: null,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.orderArticlesByArticleNumberLike({
            orderNumberLike: value,
          });
          this.noSearch = true
        }
      }, 500);
    },

    searchCN(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.noSearchTwo = false
          this.orderItemsInProduction({
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            orderItemState: null,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return
        } else {
          this.orderArticlesByArticleNumberLike({
            articleNumberLike: value,
          });
          this.noSearchTwo = true
        }
      }, 500);
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    ...mapActions(['orderItemsInProduction', 'sentStock', 'movingLocations', 'orderArticlesByArticleNumberLike', 'loadOrderTrackByOnAndAn']),
    async fetch() {
      this.orderItemsInProduction({ pageNumber: this.pageNumber, pageSize: this.pageSize })
    },
    showLocations(orderNumber, orderItemId, articleNumber) {
      this.orderNumberState = orderNumber
      this.orderItemIdState = orderItemId
      this.articleNumber = articleNumber
      this.movingLocations()
    },
    returnToStock(value) {
      this.sentStock({
        object: value,
        successCallback: () => {
          this.orderItemsInProduction({
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          });
        },
      })
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    async trackOrder(on, oi) {
      this.isSidebarOpen = true
      this.orderss = on
      this.orderid = oi
      await this.loadOrderTrackByOnAndAn({
        orderNumber: on,
        orderItemId: oi,
        pageNumber: 1,
        pageSize: this.pageSize,
        successCallback: () => {
        },
      })
    },
    async searchByOn(value) {
      this.searchOrder = value.orderNumber
      this.noSearch = false
      await this.orderItemsInProduction({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        orderNumber: value.orderNumber,
      })
    },

    async searchByCn(value) {
      this.searchCN = value.nameToDisplay
      const { orderNumber, orderItemId } = value;
      this.noSearchTwo = false
      await this.orderItemsInProduction({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        orderNumber,
        orderItemId,
      })
    },

    showOrderNumber() {
      this.toggleSearch = false;
      this.searchOrder = '';
      this.showw = true;
      this.orderItemsInProduction({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        orderNumber: null,
      })
    },

    showArticleNumber() {
      this.toggleSearch = true;
      this.searchCN = '';
      this.showw = false
      this.orderItemsInProduction({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        orderItemState: null,
      })
    },

    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getOrderArticleByArticleNumber.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    onArrowDown1(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getOrderArticleByArticleNumber.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp1(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getOrderArticleByArticleNumber[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    inputChanged1(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByCn(this.getOrderArticleByArticleNumber[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
  },
}
</script>

<style lang="scss" scoped>
.team_table td{
    padding: 16px;
}
.dropdrop::-webkit-scrollbar {
    display: none;
}
.dropdrop {
    display: inline-block;
    overflow: auto;
    position: absolute;
    background: white;
    width: 227px;
    box-shadow: none;
}

input[name="search-box"] {
    display: block;
    max-width: 500px;
    width: 95%;
    padding: 0.5rem 1rem;
    padding-left: 25px;
}
.search-item {
    padding: 5px;
    max-width: 500px;
    width: 100%;
    font-weight: 400;
    color: #82868c;
    font-size: 1rem;
    border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
    border-bottom: none;
}
.search-item:hover {
    background: #82868c;
    color: white;
}
.search-item hr {
    color: lightgray;
    border-top: none;
    margin: -1.3rem 0 0.5rem 0;
}
.is-active {
    background-color: #dedede;
}

.active2 {
    font-weight: bold;
    color: $base-color;
}
</style>
