<template>
  <div
    style="margin-top: 0px;"
  >
    <section class="mainComponent">
      <b-tabs>
        <b-tab>
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("AdminAuthorise") }}</strong>
          </template>
          <div
            class="flex"
          >
            <AdminOrders
              :create-order="true"
              @sendON="getON"
              @orderIDName="orderID"
              @sendOrderState="sendOrderStatee"
            />
            <ArticleOrderAdmin
              :order-id="orderNumber"
              :create-order="true"
              :order-state-of-order="orderStateOfOrder"
            />

          </div>
        </b-tab>
        <b-tab style="width:100%">
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("CreationDate") }}</strong>
          </template>
          <div style="width:100%">
            <AdminSelectedOrders />
          </div>
        </b-tab>
        <b-tab style="width:100%">
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("ConfigurationsOrders") }}</strong>
          </template>
          <div style="width:100%">
            <ConfigurationAdmins />
          </div>
        </b-tab>
      </b-tabs>

    </section>

  </div></template>

<script>
import { mapGetters } from 'vuex';
import ArticleOrderAdmin from '@/components/AdminAuthorization/ArticleOrderAdmin.vue'
import AdminOrders from '@/components/AdminAuthorization/AdminOrders.vue'
import AdminSelectedOrders from '@/components/AdminAuthorization/AdminSelectedOrders.vue'
import ConfigurationAdmins from '@/components/AdminAuthorization/ConfigurationAdmins.vue'
import { client } from '@/domainConfig'

export default {
  name: 'Home',
  components: {
    AdminOrders,
    ArticleOrderAdmin,
    AdminSelectedOrders,
    ConfigurationAdmins,
  },
  data() {
    return {
      orderNumber: '',
      productMatObj: {},
      orderIdName: null,
      client: client.clientName,
      orderStateOfOrder: null,

    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),
  },
  watch: {
    // eslint-disable-next-line func-names
    inside(item) {
      this.productMatObj = item
    },
  },
  methods: {
    inside(item) {
      this.productMatObj = item
    },
    sendOrderStatee(value) {
      this.orderStateOfOrder = value
    },
    getON(id) {
      this.orderNumber = id
    },
    orderID(value) {
      this.orderIdName = value
    },
  },
}
</script>

<style scoped lang="scss">

.mainComponent {
    padding: 75px 15px 15px 120px;
}

@media screen and (max-width: 1440px){
  .mainComponent{
    padding: 75px 15px 15px 100px;
  }
}

@media screen and (max-width: 1340px){
  .mainComponent{
    padding: 75px 15px 15px 90px;
  }
}
 @media screen and (max-width: 1190px) {
  .mainComponent {
    padding: 90px 0.75rem 0 0.75rem;
    justify-content: center;
  }
  .flex {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
 }
 @media screen and (max-width: 780px) {
  .mainComponent {
    .flex {
      flex-direction: column;
      .color-listing__wrapper {
        margin-left: 0;
      }
    }
  }
 }
</style>
