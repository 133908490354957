<template>
  <div>
    <b-modal
      id="on-hold-item"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ReturnOnHold')"
      hide-footer
      size="md"
      @close="onCancel"
    >
      <div style="display: flex;flex-direction: column;gap: 10px">
        <b-form-group
          :label="$t('Location')"
        >
          <vue-select
            v-model="$v.locationId.$model"
            :options="getLocationsMoving"
            :placeholder="$t('Select')"
            :state="validateState('locationId')"
            :reduce="(e) => e.locationId"
            label="locationName"
          />
          <p
            v-if="!locationId && buttonSubmit"
            class="invalid"
          >
            {{ $t('This_is_a_required_field') }}
          </p>
        </b-form-group>
        <b-form-group
          :label="$t('Description')"
        >
          <b-form-textarea
            v-model="$v.notes.$model"
            :state="validateState('notes')"
            :placeholder="$t('Write..')"
          />
          <p
            v-if="!notes && buttonSubmit"
            class="invalid"
          >
            {{ $t('This_is_a_required_field') }}
          </p>
        </b-form-group>
      </div>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';



export default {
  components: {
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
  props: ['orderItemIdState', 'orderNumberState', 'clientNameStatus'],
  data() {
    return {
      locationId: null,
      notes: null,
      buttonSubmit: false,
    };
  },
  validations: {
    locationId: {
      required,
    },
    notes: {
      required,
    },
  },
  computed: {
    ...mapGetters(['getLocationsMoving']),
  },
  watch: {
  },
  methods: {
    ...mapActions([]),
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.buttonSubmit = true
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const objekti = {
        clientId: this.clientNameStatus,
        orderItemId: this.orderItemIdState,
        locationId: this.locationId,
        notes: this.notes,
      }
      this.$emit('returnBackHold', objekti)
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.locationId = null
      this.notes = null
      this.buttonSubmit = false
    },
  },
};
</script>

<style lang="scss" scoped>
.invalid {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: var(--bs-form-invalid-color);
}

</style>
