<template>
  <div
    class="location"
    style="border: 1px solid lightgray; margin-top: 47px"
  >
    <div style="width: 100%;">
      <table
        class="team_table"
        style="width: 100%; box-shadow: none; font-size: 13px;cursor:auto"
      >
        <thead>
          <tr>
            <th>
              {{ $t('Location') }}
            </th>
            <th>
              {{ $t('Amount') }}
            </th>
            <th>
              {{ $t('AddToOrder') }}
            </th>
            <th>
              {{ $t('DeleteStock') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(article, index) in getTotalStockGroupByLocation"
            :key="index"
          >
            <td>{{ $t(article.locationName) }}</td>
            <td>{{ $t(article.totalAmountInStock) }}</td>
            <td
              v-b-modal.add-quantity
              @click="callQuantity(article)"
            >
              <button
                class="button"
              >
                {{ $t('AddToOrder') }}
              </button>
            </td>
            <td>
              <button
                v-b-modal.delete-quantity
                class="button"
                type="button"
                @click.stop="allQuantity(article)"
              >
                {{ $t('DeleteStock') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddQuantityOrder
      :article-name="articleName"
      :location-values="locationValues"
      @addQuantityToOrder="addToOrder"
    />
    <DeleteQuantity
      :max-quantity="maxQuantity"
      :locationNameStock="locationNameStock"
      :article-num="articleName?.articleNumber"
      @deleteQuantity="quantityRemove"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AddQuantityOrder from '@/components/productsInStock/modals/AddQuantityOrder.vue'
import DeleteQuantity from '@/components/productsInStock/DeleteQuantity.vue'

export default {
  components: {
    AddQuantityOrder,
    DeleteQuantity,
  },
  props: ['articleName', 'articleList'],
  data() {
    return {
      maxQuantity: 0,
      locationNameStock: null,
      location: null,
      locationValues: null,
    }
  },
  computed: {
    ...mapGetters(['getTotalStockGroupByLocation']),
  },
  watch: {
  },
  methods: {
    ...mapActions(['removeQuantityFromStock', 'totalStockGroupedByLocation']),
    quantityRemove(value) {
      this.removeQuantityFromStock({
        object: value,
        successCallback: () => {
          this.totalStockGroupedByLocation({
            articleNumber: this.articleName.articleNumber,
          })
          this.locationNameStock = null
          this.maxQuantity = null
        },
      })
    },
    allQuantity(value) {
      this.maxQuantity = value.totalAmountInStock
      this.locationNameStock = {
        locationName: value.locationName,
        locationId: value.locationId,
      }
    },
    callQuantity(value) {
      this.locationValues = value
    },
    addToOrder(value) {
      const object = {
        ...this.locationValues,
        ...this.articleName,
        quantity: value,
        currency: '$',
        pickUpDate: null,
        probeDates: [],
        bodyMeasurements: {
          thorax: null,
          aroundBust: null,
          aroundUnderBust: null,
          waist: null,
          aroundHips: null,
          aroundLeg: null,
          aroundKnee: null,
          ankle: null,
          breastReduction: null,
          interBust: null,
          frontBody: null,
          chestDistance: null,
          length1: null,
          length2: null,
          length3: null,
          length4: null,
          aroundSleeve: null,
          elbow: null,
          wrist: null,
          aroundNeck: null,
          backBody: null,
          shoulders: null,
          back: null,
          declineOfTheHips: null,
          sleeveLength: null,
        },
        showFrontBody: true,
        priceNotes: null,
        paidNotes: null,
        defaultBodyMeasurements: true,
        size: null,
      }
      this.$emit('addToOrder', object)
    },
  },
}
</script>

<style scoped>
.team_table td {
  padding: 10px 9px !important;
}
.location {
  width: 50%;
}
.green {
  color: green;
}

.red {
  color: red;
}

.orange {
  color: orange;
}
@media screen and (max-width: 1200px) {
  .location {
    width: 100%;
  }
}
</style>
