<template>
  <div style="display:flex;gap:10px;">
    <div class="tableGap">
      <div>
        <button
          v-b-modal.add-order-type
          class="button"
        >
          {{ $t('AddOrderType') }}
        </button>
      </div>
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              {{ $t('No') }}
            </th>
            <th>
              {{ $t('OrderType') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(order,index) in transformOrderTypes.slice().reverse()"
            :key="index"
          >
            <td>
              {{ transformOrderTypes.length - index }}.
            </td>
            <td>
              {{ $t(order.orderType) }}
            </td>
          </tr>
        </tbody>
      </table>
      <AddOrderType
        @addOrderTypes="addType"
      />
    </div>
    <div class="tableGap">
      <div style="display:flex;gap:10px;">
        <button
          v-b-modal.add-prefix
          class="button"
        >
          {{ $t('AddPrefix/PostFix') }}
        </button>
        <!-- <b-form-group
        style="width:20%"
        >
          <vue-select
            v-model="settingsFix"
            :options="settingsFixes"
            :clearable="false"
          />
        </b-form-group> -->
      </div>
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              {{ $t('No') }}
            </th>
            <th>
              {{ $t('Name') }}
            </th>
            <th>
              {{ $t('Type') }}
            </th>
            <th style="text-align: center;">
              {{ $t('Activate/Deactivate') }}
            </th>
            <th >
              {{ $t('Status') }}
            </th>
            <th style="text-align:center;">
              {{ $t('Edit') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(order,index) in getOrderNumberSetings.slice().reverse()"
            :key="index"
          >
            <td>
              {{ getOrderNumberSetings.length - index }}.
            </td>
            <td>
              {{ order.name }}
            </td>
            <td>
              {{ order.type }}
            </td>
            <td style="text-align:center;">
              <toggle-button
                v-model="order.status"
                :value="order.status"
                @change="deactivateOrActivate(order.id, order.status)"
              />
            </td>
            <td v-if="order.status == true">
             <p style="color:green"> {{ $t('Active') }} </p>
            </td>
            <td v-else>
             <p style="color:red"> {{ $t('NotActive') }} </p>
            </td>
            <td style="text-align: center;">
              <button
                v-b-modal.edit-postfix
                class="button"
                @click="editPOST(order)"
              >
                {{ $t('EditPostfix') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <AddPrefix
        @prefixMethod="prefixOrPostfix"
      />
      <EditPrefix
        :edit-postfix="editPostfix"
        @editPostfix="editPrefixes"
      />
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import AddOrderType from './modals/AddOrderType.vue';
import AddPrefix from './modals/AddPrefix.vue';
import EditPrefix from './modals/EditPrefix.vue';

export default {
  components: {
    AddOrderType,
    AddPrefix,
    EditPrefix,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: [],
  data() {
    return {
      settingsFix: 'Postfix',
      settingsFixes: ['Postfix', 'Prefix'],
      editPostfix: null,
    }
  },
  computed: {
    ...mapGetters(['getOrderTypes', 'getOrderNumberSetings']),
    transformOrderTypes() {
      const orderTypes = this.getOrderTypes
        .map((order) => {
          return {
            value: order.orderType,
            text: order.orderType,
            orderType: order.orderType,
          };
        });
      return [...orderTypes];
    },
  },
  watch: {
    settingsFix(value) {
      if (value) {
        this.loadOrderNumberSettings({
          type: value,
        })
      }
    },
  },
  mounted() {
    this.loadOrderTypes()
    this.loadOrderNumberSettings({
      type: this.settingsFix,
    })
  },
  methods: {
    ...mapActions(['loadOrderTypes', 'deactivateType', 'editPrefixPostfix', 'addPrefixPostfix', 'loadOrderNumberSettings', 'loadOrderTypesSuccess', 'adminAddOrderType']),
    editPOST(value) {
      this.editPostfix = value
    },
    deactivateOrActivate(id, value) {
      this.deactivateType({
        orderNumberSettingsId: id,
        status: value,
        successCallback: () => {
          this.loadOrderNumberSettings({
            type: this.settingsFix,
          })
        },
      })
    },
    addType(value) {
      this.adminAddOrderType({
        name: value,
        successCallback: () => {
          this.loadOrderTypesSuccess()
        },
      })
    },
    prefixOrPostfix(value) {
      this.addPrefixPostfix({
        object: value,
        successCallback: () => {
          this.loadOrderNumberSettings({
            type: this.settingsFix,
          })
        },
      })
    },
    editPrefixes(value) {
      this.editPrefixPostfix({
        object: value,
        successCallback: () => {
          this.loadOrderNumberSettings({
            type: this.settingsFix,
          })
          this.editPostfix = null
        },
      })
    },
  },
}
</script>

  <style scoped lang="scss">
  .team_table td {
    padding: 16px 9px !important;
  }
  .tableGap {
    display:flex;
    flex-direction:column;
    gap:10px;
    width:100%;
    margin-top:10px;
  }
  </style>
