<template>
  <div
    v-if="articleList.length > 0"
    style="border: 1px solid lightgrey; border-radius: 12px;padding: 10px;margin-top: 10px;"
  >
    <section
      v-for="(articles, index) in articleList"
      :key="index"
      class="custom-modal__wrapper"
      style="margin-bottom: 20px; flex-direction: column"
    >
      <div style="display:flex;justify-content:space-between;align-items:center;">
        <div class="paragraphText">
          <h3>{{ articles.articleNumber }}</h3>
          <img
            :src="articles.photos"
            style="max-width: 80px;"
          >
          <div>
            <p style="margin: 0px;font-weight: 600;">
              {{ $t('Location') }}: {{ articles.locationName }}
            </p>
          </div>
        </div>
        <div
          style="display:flex;gap:5px;align-items: center;"
        >
          <b-icon-trash
            style="color:black;cursor:pointer;"
            @click="removeArticles(index)"
          />
          <b-button
            variant="link"
            class="collapse-button"
            @click="toggleSection(index)"
          >
            <b-icon-chevron-down
              v-if="!isSectionClosed(index)"
              style="color:black !important;"
            />
            <b-icon-chevron-up
              v-if="isSectionClosed(index)"
              style="color:black !important;"
            />
          </b-button>
        </div>
      </div>
      <b-collapse :visible="!isSectionClosed(index)">
        <div
          class="skica-container"
          style="margin-top:10px;"
        >
          <div
            style="display:flex;justify-content:space-between;"
          >
            <button
              v-if="!isBodyClosed(index)"
              type="button"
              class="button"
              @click="articles.showFrontBody = !articles.showFrontBody"
            >
              {{ $t('FlipBody') }}
            </button>
            <button
              v-if="isBodyClosed(index)"
              type="button"
              class="button"
            >
              {{ $t('BodyClosed') }}
            </button>
            <b-button
              variant="link"
              class="collapse-button"
              @click="toggleSectionBody(index)"
            >
              <b-icon-chevron-down
                v-if="!isBodyClosed(index)"
                style="color:black !important;"
              />
              <b-icon-chevron-up
                v-if="isBodyClosed(index)"
                style="color:black !important;"
              />
            </b-button>
          </div>
          <b-collapse
            :visible="!isBodyClosed(index)"
          >
            <div
              v-if="articles.showFrontBody"
              class="front-body"
            >
              <img
                src="../../../assets/images/front-body.svg"
                alt=""
              >
              <div class="toraks">
                <b-form-input
                  v-model="articles.bodyMeasurements.thorax"
                  :placeholder="$t('toraks')"
                  class="toraks1"
                />
              </div>
              <div class="rrethiGjoksit">
                <b-form-input
                  v-model="articles.bodyMeasurements.aroundBust"
                  :placeholder="$t('rrethiGjoksit')"
                  class="rrethiGjoksit1"
                />
              </div>
              <div class="rrethiNenGjoksit">
                <b-form-input
                  v-model="articles.bodyMeasurements.aroundUnderBust"
                  :placeholder="$t('rrethiNenGjoksit')"
                  class="rrethiNenGjoksit1"
                />
              </div>
              <div class="beli">
                <b-form-input
                  v-model="articles.bodyMeasurements.waist"
                  :placeholder="$t('beli')"
                  class="beli1"
                />
              </div>
              <div class="vithet">
                <b-form-input
                  v-model="articles.bodyMeasurements.aroundHips"
                  :placeholder="$t('RrethiVitheve')"
                  class="vithet1"
                />
              </div>
              <div class="rrethiKembes">
                <b-form-input
                  v-model="articles.bodyMeasurements.aroundLeg"
                  :placeholder="$t('rrethiKembes')"
                  class="rrethiKembes1"
                />
              </div>
              <div class="rrethiGjurit">
                <b-form-input
                  v-model="articles.bodyMeasurements.aroundKnee"
                  :placeholder="$t('rrethiGjurit')"
                  class="rrethiGjurit1"
                />
              </div>
              <div class="zoguKembeve">
                <b-form-input
                  v-model="articles.bodyMeasurements.ankle"
                  :placeholder="$t('zoguKembeve')"
                  class="zoguKembeve1"
                />
              </div>

              <div class="reniaGjoksit">
                <b-form-input
                  v-model="articles.bodyMeasurements.breastReduction"
                  :placeholder="$t('reniaGjoksit')"
                  class="reniaGjoksit1"
                />
              </div>
              <div class="distancaGjoksit">
                <b-form-input
                  v-model="articles.bodyMeasurements.chestDistance"
                  :placeholder="$t('distancaGjoksit')"
                  class="distancaGjoksit1"
                />
              </div>
              <div class="nderGjoks">
                <b-form-input
                  v-model="articles.bodyMeasurements.interBust"
                  :placeholder="$t('nderGjoks')"
                  class="nderGjoks1"
                />
              </div>
              <div class="trupiPara">
                <b-form-input
                  v-model="articles.bodyMeasurements.frontBody"
                  :placeholder="$t('trupiPara')"
                  class="trupiPara1"
                />
              </div>
              <div class="gjatesia1">
                <b-form-input
                  v-model="articles.bodyMeasurements.length1"
                  :placeholder="$t('gjatesia1')"
                  class="gjatesia11"
                />
              </div>
              <div class="gjatesia2">
                <b-form-input
                  v-model="articles.bodyMeasurements.length2"
                  :placeholder="$t('gjatesia2')"
                  class="gjatesia21"
                />
              </div>
              <div class="gjatesia3">
                <b-form-input
                  v-model="articles.bodyMeasurements.length3"
                  :placeholder="$t('gjatesia3')"
                  class="gjatesia31"
                />
              </div>
              <div class="gjatesia4">
                <b-form-input
                  v-model="articles.bodyMeasurements.length4"
                  :placeholder="$t('gjatesia4')"
                  class="gjatesia41"
                />
              </div>
            </div>
            <div
              v-if="!articles.showFrontBody"
              class="back-body"
            >
              <img
                src="../../../assets/images/back-body.svg"
                alt=""
              >
              <div class="rrethiMenges">
                <b-form-input
                  v-model="articles.bodyMeasurements.aroundSleeve"
                  :placeholder="$t('rrethiMenges')"
                  class="rrethiMenges1"
                />
              </div>
              <div class="brryli">
                <b-form-input
                  v-model="articles.bodyMeasurements.elbow"
                  :placeholder="$t('brryli')"
                  class="brryli1"
                />
              </div>
              <div class="kyçi">
                <b-form-input
                  v-model="articles.bodyMeasurements.wrist"
                  :placeholder="$t('kyqi')"
                  class="kyçi1"
                />
              </div>
              <div class="rrethiQafes">
                <b-form-input
                  v-model="articles.bodyMeasurements.aroundNeck"
                  :placeholder="$t('rrethiQafes')"
                  class="rrethiQafes1"
                />
              </div>
              <div class="trupiPrapa">
                <b-form-input
                  v-model="articles.bodyMeasurements.backBody"
                  :placeholder="$t('trupiPrapa')"
                  class="trupiPrapa1"
                />
              </div>
              <div class="supet">
                <b-form-input
                  v-model="articles.bodyMeasurements.shoulders"
                  :placeholder="$t('supet')"
                  class="supet1"
                />

              </div>
              <div class="shpina">
                <b-form-input
                  v-model="articles.bodyMeasurements.back"
                  :placeholder="$t('shpina')"
                  class="shpina1"
                />
              </div>
              <div class="reniaVitheve">
                <b-form-input
                  v-model="articles.bodyMeasurements.declineOfTheHips"
                  :placeholder="$t('reniaVitheve')"
                  class="reniaVitheve1"
                />
              </div>
              <div class="gjatesiaMenges">
                <b-form-input
                  v-model="articles.bodyMeasurements.sleeveLength"
                  :placeholder="$t('gjatesiaMenges')"
                  class="gjatesiaMenges1"
                />
              </div>
            </div>
          </b-collapse>
        </div>
        <div style="display:flex;margin-top:10px;gap:15px">
          <b-form-group
            :label="$t('PickupDate')"
            style="width:20%"
          >
            <date-picker
              v-model="articles.pickUpDate"
              format="YYYY-MM-DD"
              value-type="format"
              :placeholder="$t('SelectD')"
            />
            <p
              v-if="articles.pickUpDate == null && isButtonSubmited "
              class="validationClass"
            >
              {{ $t('SelectD') }}
            </p>
          </b-form-group>
          <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px;">
            <div
              v-for="(dates, dateIndex) in articles.probeDates"
              :key="dateIndex"
              style="border:1px solid lightgrey;border-radius:12px;padding:10px;"
            >
              <div style="display:flex;gap:5px;flex-direction:column;">
                <b-form-group
                  :label="`${dates.probeName} ${dateIndex + 1}`"
                >
                  <date-picker
                    v-model="dates.dateOfProbe"
                    format="YYYY-MM-DD"
                    value-type="format"
                    :placeholder="$t('SelectD')"
                  />
                  <b-icon-trash
                    style="color:black;margin-left:5px;cursor:pointer;"
                    @click="removeProbeDate(index, dateIndex)"
                  />
                </b-form-group>
                <b-form-group
                  :label="$t('Notes')"
                >
                  <b-form-input
                    v-model="dates.notes"
                    format="YYYY-MM-DD"
                    value-type="format"
                    :placeholder="$t('SelectD')"
                  />
                </b-form-group>
                <p
                  v-if="isProbeDateMissing(index, dates)"
                  style="color:red;"
                >
                  {{ $t('PleaseFillInDateAndNotes') }}
                </p>
              </div>
            </div>
            <div style="display:flex;align-items:flex-end;">
              <div
                style="display:flex;gap:5px;align-items: center;"
              >
                <button
                  class="button"
                  type="button"
                  @click="addFittingDate(index)"
                >
                  {{ $t('AddFitting') }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="formInput">
          <b-form-group :label="$t('Price')">
            <b-form-input
              v-model.number="articles.price"
              type="number"
              placeholder="Ex. 100"
              min="0"
              @input="calculatePrice(articles)"
            />
          </b-form-group>
          <b-form-group :label="$t('Discount')">
            <b-form-input
              v-model.number="articles.discount"
              type="number"
              placeholder="Ex. 100"
              min="0"
              :max="articles.price"
              @input="calculateDiscount(articles)"
            />
          </b-form-group>
          <b-form-group :label="$t('Currency')">
            <b-form-select
              v-model="articles.currency"
              :options="currencies"
              class="currencyStyle"
              @input="calculateFromPercentage(articles)"
            />
          </b-form-group>
          <b-form-group :label="$t('PriceWithDiscount')">
            <b-form-input
              v-model.number="articles.priceWithDiscount"
              type="number"
              placeholder="Ex. 100"
              min="0"
              disabled
              :max="articles.price"
              @input="calculatePriceWithDiscount(articles)"
            />
          </b-form-group>
        </div>

        <div class="formInput">
          <b-form-group :label="$t('paidAmount')">
            <b-form-input
              v-model.number="articles.paidAmount"
              type="number"
              placeholder="Ex. 100"
              min="0"
            />
          </b-form-group>
          <b-form-group :label="$t('Quantity')">
            <b-form-input
              v-model.number="articles.quantity"
              type="number"
              placeholder="Ex. 100"
              min="0"
            />
          </b-form-group>
        </div>
        <div style="margin: 2% 1%; display: flex">
          <p class="p-0 m-0 ml-50">
            {{ $t('IncludeTax') }}
            <toggle-button
              :value="articles.includeTax"
              :sync="true"
              color="#242F6E"
              @change="updateTaxRate(index, $event)"
            />
          </p>
          <div
            v-if="articles.includeTax"
            style=" margin-left: 10px; margin-bottom: 10px"
          >
            <span>18%</span>
          </div>
        </div>
        <div
          v-for="(material, materialIndex) in articles.materialVariantDtos"
          :key="materialIndex"
          class="materialsClass"
        >
          <div style="display:flex;justify-content: space-between;padding-right: 15px">
            <label> {{ materialIndex + 1 }}.{{ $t('MeasurementType') }} <span style="font-weight:600"> {{ material.parameter ? `: ${material.parameter}`: null }} </span></label>
            <b-icon-trash
              style="cursor:pointer;"
              @click="removeMaterial(index, materialIndex)"
            />
          </div>
          <b-form-group>
            <b-form-radio-group
              v-model="material.measurementType"
              style="display:flex;gap: 10px;width:90%"
              @change="resetMaterialName(material)"
            >
              <b-form-radio
                class="form-radio"
                value="Meter"
              >
                <p>{{ $t('Meter') }} </p>
              </b-form-radio>
              <b-form-radio
                class="form-radio"
                value="Part"
              >
                <p>{{ $t('Part') }} </p>
              </b-form-radio>
              <b-form-radio
                class="form-radio"
                value="Verse"
              >
                <p>{{ $t('Verse') }} </p>
              </b-form-radio>
              <b-form-radio
                class="form-radio"
                value="Package"
              >
                <p>{{ $t('Package') }} </p>
              </b-form-radio>
              <b-form-radio
                class="form-radio"
                value="Pair"
              >
                <p>{{ $t('Pair') }} </p>
              </b-form-radio>
              <b-form-radio
                class="form-radio"
                value="Set"
              >
                <p>{{ $t('Set') }} </p>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <div style="display:flex;gap:10px;width:100%">
            <b-form-group
              :label="$t('MaterialVariant')"
              style="width:25%"
            >
              <vue-select
                v-model="material.name"
                :placeholder="$t('Description')"
                label="materialVariantName"
                :options="getMaterialOptions(material.measurementType)"
                @search="searchMaterial(material.measurementType, $event)"
                @input="updateMaterialVariantId(material.name, material)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <div
                    v-if="getMaterialVariantColorNameLike.length > 1"
                    style="display:flex;gap:5px; padding-top: 3px;width:98%"
                  >
                    <button
                      class="buttonCancel"
                      type="button"
                      :disabled="pageNumberFilter == 1"
                      @click="previousPage(material.measurementType, searchQuery)"
                    >
                      {{ $t('Previous') }}
                    </button>
                    <button
                      class="buttonSubmit"
                      type="button"
                      @click="nextPage(material.measurementType, searchQuery)"
                    >
                      {{ $t('Next') }}
                    </button>
                  </div>
                </li>
              </vue-select>
              <p
                v-if="(material.name == null || material.name == '' )&& isButtonSubmited "
                class="validationClass"
              >
                {{ $t('Please Select a material variant') }}
              </p>
            </b-form-group>
            <b-form-group
              v-if="material.measurementType == 'Part' || material.measurementType == 'Parts'"
              :label="$t(material.measurementType)"
            >
              <b-input
                v-model.number="material.quantity"
                required
                type="number"
                @input="calculateTotalPerMeter(material.pricePerMeter, material.quantity)"
              />
              <p
                v-if="material.quantity < 0.0001 && isButtonSubmited "
                class="validationClass"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>
            <b-form-group
              v-else
              :label="$t(material.measurementType)"
            >
              <b-input
                v-model.number="material.length"
                required
                type="number"
                @input="calculateTotalPerMeter(material.pricePerMeter, material.length)"
              />
              <p
                v-if="material.length < 0.0001 && isButtonSubmited "
                class="validationClass"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>
            <b-form-group
              :label="$t('pricePerMeter')"
            >
              <b-input
                v-model.number="material.pricePerMeter"
                required
                disabled
                type="number"
                @input="calculateTotalPerMeter(material.pricePerMeter, material.length || material.quantity)"
              />
            </b-form-group>
            <b-form-group
              :label="$t('PriceDiscount')"
            >
              <b-input
                v-model.number="material.pricePerMeterWithDiscount"
                required
                type="number"
                @input="calculateTotalPerMeter(material.pricePerMeterWithDiscount, material.length || material.quantity)"
              />
            </b-form-group>
          </div>
        </div>
        <button
          class="buttonNotaSmall"
          type="button"
          @click="addMaterialObject(index)"
        >
          Shto Material
        </button>
      </b-collapse>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  props: ['articleList', 'isButtonSubmited'],
  data() {
    return {
      currencies: ['€', '$', '%'],
      closedSections: [],
      closedBody: [],
    };
  },
  computed: {
    ...mapGetters([
      'getMaterialsByArticleNum',
      'getMaterialVariantColorNameLike',
      'getMaterialsVByMeter',
      'getMaterialsVByPart',
      'getMaterialsVByPackage',
      'getMaterialsVBySet',
      'getMaterialsVByPair',
      'getMaterialsVByVerse',
    ]),
  },
  watch: {
    articleList: {
      handler(newValue) {
        newValue.forEach((article) => {
          if (this.timeoutId) {
            clearTimeout(this.timeoutId);
          }
          this.timeoutId = setTimeout(() => {
            if (article.price == '' || article.price == null) {
              article.price = 0;
            }
            if (article.priceWithDiscount == '' || article.priceWithDiscount == null) {
              article.priceWithDiscount = 0;
            }
            if (article.discount == '' || article.discount == null) {
              article.discount = 0;
            }
            this.resetQuantityIfNegative(article);
          }, 100);
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.getMaterialVariantsToMDCPagination({
      pageNumber: 1,
      pageSize: 15,
      measurement: 'Meter',
    })
    this.getMaterialVariantsToMDCPagination({
      pageNumber: 1,
      pageSize: 15,
      measurement: 'Package',
    })
    this.getMaterialVariantsToMDCPagination({
      pageNumber: 1,
      pageSize: 15,
      measurement: 'Set',
    })
    this.getMaterialVariantsToMDCPagination({
      pageNumber: 1,
      pageSize: 15,
      measurement: 'Pair',
    })
    this.getMaterialVariantsToMDCPagination({
      pageNumber: 1,
      pageSize: 15,
      measurement: 'Verse',
    })
    this.getMaterialVariantsToMDCPagination({
      pageNumber: 1,
      pageSize: 15,
      measurement: 'Part',
    })
  },
  methods: {
    ...mapActions(['filterVariantByColor', 'resetAllFiltered', 'getMaterialVariantsToMDCPagination']),
    isProbeDateMissing(index, dates) {
      return (
        this.articleList[index].probeDates.length > 0
        && (!dates.dateOfProbe || !dates.notes) && this.isButtonSubmited
      );
    },
    removeArticles(index) {
      this.articleList.splice(index, 1)
    },
    toggleSection(index) {
      const sectionIndex = this.closedSections.indexOf(index);
      if (sectionIndex > -1) {
        this.closedSections.splice(sectionIndex, 1);
      } else {
        this.closedSections.push(index);
      }
    },
    isSectionClosed(index) {
      return this.closedSections.includes(index);
    },
    toggleSectionBody(index) {
      const sectionIndex = this.closedBody.indexOf(index);
      if (sectionIndex > -1) {
        this.closedBody.splice(sectionIndex, 1);
      } else {
        this.closedBody.push(index);
      }
    },
    isBodyClosed(index) {
      return this.closedBody.includes(index);
    },
    removeProbeDate(index, date) {
      this.articleList[index].probeDates.splice(date, 1)
    },
    addFittingDate(index) {
      this.articleList[index].probeDates.push({
        probeName: 'Fitting Date',
        dateOfProbe: null,
        notes: null,
      })
    },
    updateTaxRate(index, newIncludeTax) {
      this.articleList[index].includeTax = newIncludeTax.value;
      if (newIncludeTax.value) {
        this.articleList[index].taxRate = 18;
      } else {
        this.articleList[index].taxRate = 0;
      }
    },
    addMaterialObject(index) {
      this.articleList[index].materialVariantDtos.push({
        materialVariantId: null,
        name: null,
        measurementType: 'Meter',
        quantity: 0,
        length: 0,
        pricePerMeter: 0,
        pricePerMeterWithDiscount: 0,
      });
    },
    calculateTotalPerMeter(price, length) {
      this.totalPerMeter = price * length
    },
    updateMaterialVariantId(value, material) {
      if (value != null) {
        material.materialVariantId = value.materialVariantId
        material.pricePerMeter = value.pricePerMeter
        material.name = value.materialVariantName
        material.pricePerMeterWithDiscount = value.pricePerMeter
      } else {
        material.materialVariantId = null
        material.pricePerMeter = 0
        material.pricePerMeterWithDiscount = 0
        material.name = null
      }
    },
    searchMaterial(measurement, query) {
      this.searchQuery = query
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.filterVariantByColor({
          name: query,
          measurementType: measurement,
          pageNumber: 1,
          pageSize: 15,
        });
      }, 500);
    },
    getMaterialOptions(measurementType) {
      let measurement
      if (this.getMaterialVariantColorNameLike.length > 0) {
        measurement = this.getMaterialVariantColorNameLike
      } else if (measurementType === 'Meter') {
        measurement = this.getMaterialsVByMeter;
      } else if (measurementType === 'Package') {
        measurement = this.getMaterialsVByPackage;
      } else if (measurementType === 'Set') {
        measurement = this.getMaterialsVBySet;
      } else if (measurementType === 'Pair') {
        measurement = this.getMaterialsVByPair;
      } else if (measurementType === 'Verse') {
        measurement = this.getMaterialsVByVerse;
      } else {
        measurement = this.getMaterialsVByPart;
      }


      return measurement;
    },
    resetMaterialName(material) {
      material.name = null;
      material.pricePerMeter = 0
      material.pricePerMeterWithDiscount = 0
      this.resetAllFiltered()
    },
    nextPage(measurement, query) {
      this.pageNumberFilter += 1
      this.filterVariantByColor({
        name: query,
        measurementType: measurement,
        pageNumber: this.pageNumberFilter,
        pageSize: 15,
      });
    },
    previousPage(measurement, query) {
      this.pageNumberFilter -= 1
      this.filterVariantByColor({
        name: query,
        measurementType: measurement,
        pageNumber: this.pageNumberFilter,
        pageSize: 15,
      });
    },
    removeMaterial(index, materialIndex) {
      this.articleList[index].materialVariantDtos.splice(materialIndex, 1)
    },
    resetQuantityIfNegative(article) {
      if (article.quantity < 0 || article.quantity == '') {
        article.quantity = 0;
      }
    },
    calculatePrice(article) {
      if (article.price < 0) {
        article.price = 0;
      }
      this.calculateDiscount(article);
      this.calculatePriceWithDiscount(article);
    },
    calculateDiscount(article) {
      if (article.currency == '%') {
        if (article.discount < 0) {
          article.discount = 0;
        }
        if (article.discount > 100) {
          article.discount = 0;
        }
        this.calculatePriceWithDiscount(article);
      } else {
        if (article.discount < 0) {
          article.discount = 0;
        } else if (article.discount > article.price) {
          article.discount = 0;
        }
        this.calculatePriceWithDiscount(article);
      }
    },
    calculatePriceWithDiscount(article) {
      if (article.currency === '%') {
        if (article.discount > 0) {
          article.priceWithDiscount = article.price - (article.price * article.discount) / 100;
        } else {
          article.priceWithDiscount = article.price;
        }
      } else if (article.currency !== '%') {
        if (article.discount > 0) {
          article.priceWithDiscount = article.price - article.discount;
        } else {
          article.priceWithDiscount = article.price;
        }
      }
      if (article.priceWithDiscount > article.price) {
        article.priceWithDiscount = article.price;
      }
    },
    calculateFromPercentage(article) {
      this.calculateDiscount(article);
    },
  },
};
</script>

    <style scoped lang="scss">

.form-radio{
    display:flex;
    align-items: center;
    gap:5px;
    width:100%;
  }
  .form-radio p {
    margin-bottom: 0px;
    font-size: 20px;
  }
     .validationClass{
    color: red;
    padding-top: 5px;
    margin-bottom: 0px;
  }
  .materialsClass{
    display:flex;
    flex-direction: column;
    gap:10px;
    margin-top: 10px;
  }
    .buttonNotaSmall {
      margin-top: 10px;
  background: #FF274F !important;
  font-weight: 500;
  font-size: 15px !important;
  color: $digit-white;
  border-radius: 6px;
  height: 35px;
  width: 140px;
  border: none;
  padding: 6px;
  &--hidden {
    visibility: hidden;
  }
}

.buttonNotaSmall:hover {
  margin-top: 10px;
  border: 1px solid #FF274F !important;
  background: white !important;
  color: #FF274F !important;
  transition: ease-in-out 0.3s;
  width: 140px;
  height: 35px;
}
    .custom-modal__wrapper {
      display: flex;
      justify-content: space-between;
      border: 1px solid lightgray;
      border-radius: 10px;
      padding: 10px;
      margin-top: 10px;
    }
    .paragraphText {
      display: flex;
      gap: 10px;
      align-items: center;
    }
    .paragraphText h3 {
      color: red;
      width: auto;
      background-color: red;
      color: white;
      font-size: 20px;
      padding: 10px;
      border-radius: 12px;
      margin: 0px;
    }
    .formInput {
      display: flex;
      gap: 10px;
      padding-top: 10px;;
    }
    .currencyStyle {
      display: block;
      padding: 0.469rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .back-body {
  position: relative;
  img {
    width: 100%;
  }
  .rrethiMenges {
    display: flex;
    position: absolute;
    top: 122px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .brryli {
    display: flex;
    position: absolute;
    top: 166px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .kyçi {
    display: flex;
    position: absolute;
    top: 192px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .rrethiQafes {
    display: flex;
    position: absolute;
    top: 30px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .trupiPrapa {
    display: flex;
    position: absolute;
    top: 211px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .supet {
    display: flex;
    position: absolute;
    top: 78px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .shpina {
    display: flex;
    position: absolute;
    top: 135px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .reniaVitheve {
    display: flex;
    position: absolute;
    bottom: 253px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .gjatesiaMenges {
    display: flex;
    position: absolute;
    top: 77px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
}
.front-body {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-bottom: 20px;
  img {
    width: 100%;
  }
  .betweenCol{
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .input{
    max-width: 100%;
    height: 51px;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .toraks {
    display: flex;
    position: absolute;
    top: 80px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .rrethiGjoksit {
    display: flex;
    position: absolute;
    top: 125px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .rrethiNenGjoksit {
    display: flex;
    position: absolute;
    top: 170px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .beli {
    display: flex;
    position: absolute;
    top: 215px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .vithet {
    display: flex;
    position: absolute;
    top: 263px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .rrethiKembes {
    display: flex;
    position: absolute;
    bottom: 222px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .rrethiGjurit {
    display: flex;
    position: absolute;
    bottom: 158px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .zoguKembeve {
    display: flex;
    position: absolute;
    bottom: 48px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .reniaGjoksit {
    display: flex;
    position: absolute;
    top: 65px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .nderGjoks {
    display: flex;
    position: absolute;
    top: 158px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .trupiPara {
    display: flex;
    position: absolute;
    top: 200px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .distancaGjoksit {
    display: flex;
    position: absolute;
    top: 110px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .gjatesia1 {
    display: flex;
    position: absolute;
    bottom: 240px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .gjatesia2 {
    display: flex;
    position: absolute;
    bottom: 145px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .gjatesia3 {
    display: flex;
    position: absolute;
    bottom: 80px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
  .gjatesia4 {
    display: flex;
    position: absolute;
    bottom: 20px;
    left: 55px;
    max-width: 180px;
    width: 100%;
    height: 40px;
  }
}
    </style>
