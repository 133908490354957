<template>
  <b-modal
    id="create-order-articles"
    ref="modal"
    header-class="headerModal"
    centered
    size="xl"
    title="Create order modal"
    hide-footer
    @close="onCancel"
  >
    <p
      v-if="clientError && buttonSubmited"
      style="color:red;font-weight: 600;"
    >
      {{ $t('NoClientFound') }}
    </p>
    <ClientSearch
      @sentClientData="sentClientDatas"
    />
    <p
      v-if="articleList.length == 0 && buttonSubmited"
      style="color:red;font-weight: 600;margin-top: 10px;margin-bottom: 5px"
    >
      {{ $t('NoArticlesFound') }}
    </p>
    <ArticleSell
      :article-list="articleList"
      :is-button-submited="buttonSubmited"
    />
    <div style="margin-left:5px;margin-top: 10px;width:100%;border:3px solid lightgray;border-radius: 6px;padding:15px">
      <div style="display:flex;gap:10px">
        <b-form-group
          :label="$t('SellDate')"
          style="margin-top:5px;margin-bottom:5px;width:100%"
        >
          <date-picker
            v-model="sellDate"
            format="YYYY-MM-DD"
            value-type="format"
            :placeholder="$t('SelectD')"
            style="width:100%"
          />
        </b-form-group>
        <b-form-group
          :label="$t('SelectTeam')"
          style="width:100%"
        >
          <vue-select
            v-model="assignmentTeamNameId"
            :options="getTeamsNames"
            :reduce="(e) => e.teamNameId"
            label="teamName"
            :placeholder="$t('Select')"
            style="width: 100%;"
          />
          <p
            v-if="assignmentTeamNameId == null && buttonSubmited"
            style="color:red;font-weight:600"
          >
            {{ $t('SelectTeam') }}
          </p>
        </b-form-group>
        <b-form-group
          :label="$t('SelectState')"
          style="width:100%"
        >
          <vue-select
            v-model="state"
            :options="statesName"
            :reduce="(e) => e.value"
            label="name"
            :placeholder="$t('Select')"
            style="width: 100%;"
          />
          <p
            v-if="state == null && buttonSubmited"
            style="color:red;font-weight:600"
          >
            {{ $t('SelectState') }}
          </p>
        </b-form-group>
      </div>
    </div>
    <div style="margin-left:5px;margin-top: 10px;width:100%;border:3px solid lightgray;border-radius: 6px;padding:15px">
      <b-form-group
        :label="$t('ShippingCost')"
        style="width:30%"
      >
        <div
          style="display:flex;flex-direction:column;gap:5px;"
        >
          <div style="display:flex;flex-direction:row;gap:5px;">
            <b-form-input
              v-model.number="shippings.cost"
              :placeholder="$t('Cost')"
              type="number"
            />
            <b-form-select
              v-model="shippings.currency"
              dropup
              :options="[{ value: null, text: 'None' }, ...currencies]"
              aria-placeholder="Select order type"
              aria-describedby="input-1-live-feedback"
              style="display: block;
                          padding: 0.469rem 0.75rem;
                          font-size: 1rem;
                          font-weight: 400;
                          line-height: 1.5;
                          color: #212529;
                          background-color: #fff;
                          background-clip: padding-box;
                          border: 1px solid #ced4da;
                          border-radius: 0.25rem;
                          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
              :disabled="shippings.cost == null || shippings.cost == ''"
            />
          </div>
          <b-form-group
            :label="$t('paidAmount')"
            style="width: 100%"
          />
          <b-form-input
            v-model.number="shippings.paidAmount"
            :placeholder="$t('paidAmount')"
            type="number"
            :disabled="shippings.cost == null || shippings.cost == ''"
          />
          <b-form-group
            :label="$t('Notes')"
            style="width:100%"
          >
            <b-form-textarea
              v-model="shippings.notes"
              style="width:100%"
              :placeholder="$t('Select')"
              type="number"
            />
          </b-form-group>
        </div>
      </b-form-group>
      <b-form-group
        :label="$t('SelectAccount')"
        style="margin-top:5px;margin-bottom:5px;width:30%"
      >
        <vue-select
          v-model="shippings.accountId"
          :options="getAccountDetails"
          required
          label="name"
          :reduce="(account) => account.accountId"
          :placeholder="$t('SelectAccount')"
          aria-describedby="input-1-live-feedback"
          :disabled="(shippings.cost == null || shippings.cost == '') || (shippings.paidAmount == null || shippings.paidAmount == '')"
        />
        <p
          v-if="shippings.cost != null && shippings.paidAmount != null && shippings.accountId == null"
          style="color:red"
        >{{ $t('FillData') }}</p>
      </b-form-group>
    </div>
    <div style="display: flex;flex-direction:column;margin: 10px 0px 10px 5px;border:3px solid lightgray;border-radius: 6px;padding:15px">
      <div style="display:flex;gap:10px;">
        <b-form-group
          :label="$t('AddAccount')"
          style="width:100%"
        >
          <vue-select
            v-model="accountId"
            :options="getAccountDetails"
            :reduce="(e) => e.accountId"
            label="name"
            :placeholder="$t('Select')"
            style="width: 100%;"
          />
        </b-form-group>
        <b-form-group
          v-if="getLoggedInUser.role !== 'SalesPerson' && getLoggedInUser.role !== 'OnlineSales' "
          :label="$t('SelectSalesPerson')"
          style="width:100%"
        >
          <vue-select
            v-model="roleId"
            :options="getUsersMultipleRoles"
            required
            label="fullName"
            :reduce="(account) => account.userID"
            :placeholder="$t('SelectSalesPerson')"
            aria-describedby="input-1-live-feedback"
            style="width: 100%;"
          />
        </b-form-group>
      </div>
      <b-form-group
        :label="$t('InoviceRemark')"
        style="width:100%;padding-top:5px;"
      >
        <b-form-textarea
          v-model="invoiceRemark"
          :placeholder="$t('Notes')"
          style="width:100%"
        />
      </b-form-group>
    </div>
    <div style="display: flex;">
      <AddFile
        ref="addFile1"
        :folder="'OrderPhotos'"
        :label-text="$t('UploadSketch')"
        :file-data-type="'Scetch'"
        :file-name="createFileDataDto"
      />
      <AddFile
        ref="addFile2"
        :folder="'OrderPhotos'"
        :label-text="$t('UploadContract')"
        :file-data-type="'Contract'"
        :file-name="createFileDataDto"
      />
      <AddFile
        ref="addFile3"
        :folder="'OrderPhotos'"
        :label-text="$t('OtherDocuments')"
        :file-data-type="'OtherDocument'"
        :file-name="createFileDataDto"
      />
    </div>
    <div v-if="client == 'ValdrinSahiti'"
    style="display: flex;gap:15px;"
    >
      <b-form-group
        :label="$t('OrderNumber')"
        style="margin-top: 20px;width:100%"
      >
        <b-input
          v-model="orderNumber"
          style="width:100%;"
          :placeholder="$t('OrderName')"
          @input="isOrderNumber(orderNumber)"
        />
        <p
          v-if="getIsOrderNumber == true"
          style="color:red;"
        >
          {{ $t('ThisOrderN') }}
        </p>
      </b-form-group>
      <b-form-group
        :label="$t('Postfix')"
        style="margin-top: 20px;width:80%"
      >
        <vue-select
          v-model="settingsFix"
          :options="getOrderNumberSetings"
          label="name"
          :reduce="(e) => e.name"
          :placeholder="$t('Select')"
        />
        <p
          v-if="(orderNumber != '' && settingsFix == null) && buttonSubmited == true"
          style="color:red;"
        >
          {{ $t('Select postfix') }}
        </p>
      </b-form-group>
    </div>

    <b-form-group
      :label="$t('Notes')"
    >
      <b-form-textarea
        v-model="description"
        :placeholder="$t('RequestedChangesByCustomer')"
      />
    </b-form-group>
    <div
      class="buttonsEverywhere"
      style="margin-top: 0%"
    >
      <button
        type="button"
        variant="none"
        style="margin-top: 20px;"
        class="buttonSubmit"
        :disabled="getIsOrderNumber == true"
        @click="onSubmit"
      >
        {{ $t('Submit') }}
      </button>
      <b-button
        type="reset"
        variant="danger"
        class="buttonCancel"
        style="margin-right: 15px; margin-top: 20px; background: white; color: black; border-color: white;font-weight: 400;"
        @click="onCancel"
      >
        {{ $t('Cancel') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';
import AddFile from '@/modals/AddFile.vue'
import { client } from '@/domainConfig';
import ClientSearch from './modals/ClientSearch.vue';
import ArticleSell from './modals/ArticleSell.vue';
import 'vue2-datepicker/index.css';


export default {
  components: {
    ClientSearch,
    ArticleSell,
    DatePicker,
    AddFile,
  },
  props: ['articleList'],
  data() {
    return {
      client: client.clientName,
      settingsFix: null,
      bodyMeasurementDto: {},
      orderNumber: '',
      assignmentTeamNameId: null,
      state: null,
      createFileDataDto: [],
      includeTax: false,
      taxRate: 0,
      buttonSubmited: false,
      paidAmount: null,
      sellDate: null,
      pickUpDate: null,
      invoiceRemark: null,
      description: null,
      roleId: null,
      accountId: null,
      clientData: null,
      clientError: false,
      shippings: {
        cost: null,
        accountId: null,
        currency: '€',
        notes: '',
        paidAmount: null,
      },
      currencies: ['€', '$', 'CHF'],
    }
  },
  computed: {
    ...mapGetters(['getAccountDetails', 'getOrderNumberSetings', 'getIsOrderNumber', 'getLocationsMoving', 'getOrderItemStates', 'getTeamsNames', 'getLoggedInUser', 'getAllStoresDropdown', 'getUsersMultipleRoles']),
    totalPrice() {
      return this.articleList.reduce((total, article) => {
        return total + ((article?.price || 0) * (article?.quantity || 0));
      }, 0);
    },
    totalDiscount() {
      return this.articleList.reduce((total, article) => {
        return total + ((article?.discount || 0));
      }, 0);
    },
    totalPriceWithDiscount() {
      return this.articleList.reduce((total, article) => {
        return total + ((article?.priceWithDiscount || 0) * (article?.quantity || 0));
      }, 0);
    },
    statesName() {
      return this.getOrderItemStates.map((item) => {
        return {
          name: this.$t(item),
          value: item,
        }
      })
    },
  },
  watch: {
    includeTax(newIncludeTax) {
      if (newIncludeTax) {
        this.taxRate = 18;
      } else {
        this.taxRate = 0;
      }
    },
  },
  mounted() {
    this.usersByMultipleRoles({
      status: true,
      object: ['SalesPerson', 'OnlineSales', 'Designer'],
    })
    this.accountDetails(
      {
        isActive: true,
        bankId: null,
      },
    )
    this.teamNames({
      startFilterPickUpDate: null,
      endFilterPickUpDate: null,
    })
    this.movingLocations()
    this.orderItemStates()
    this.loadOrderNumberSettings({
      type: 'Postfix',
    })
  },
  methods: {
    ...mapActions(['accountDetails', 'loadOrderNumberSettings', 'isOrderNumberAvailable', 'resetIsOrderNumberAvailable', 'movingLocations', 'orderItemStates', 'teamNames', 'usersByMultipleRoles', 'loadAllStoresByDropdown']),
    isOrderNumber(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.isOrderNumberAvailable({
          orderNumber: value,
        })
      }, 500);
    },
    sentClientDatas(value) {
      this.clientData = value.clientData
      this.clientError = value.errorClient
      this.bodyMeasurementDto = value.bodyMeasurementDto
      this.articleList.forEach((article) => {
        article.bodyMeasurements = { ...this.bodyMeasurementDto };
      });
    },
    async onSubmit() {
      this.buttonSubmited = true
      try {
        if (this.clientError && this.buttonSubmited) {
          return;
        }
        if (this.shippings.cost != null) {
          if (this.shippings.cost != null && this.shippings.paidAmount != null && this.shippings.accountId == null) {
            return
          }
        }
        if (this.articleList.length == 0 && this.buttonSubmited) {
          return
        }
        const hasNullPickUpDate = this.articleList.some((item) => { return item.pickUpDate === null });
        if (hasNullPickUpDate) {
          return;
        }
        if ((this.assignmentTeamNameId == null && this.buttonSubmited) || (this.state == null && this.buttonSubmited)) {
          return
        }
        const invalidProbeDates = this.articleList.some((item) => {
          if (item.probeDates && item.probeDates.length > 0 && this.buttonSubmited) {
            return item.probeDates.some((probe) => {
              return probe.dateOfProbe == null || probe.notes == null;
            });
          }
          return false;
        });

        if (invalidProbeDates) {
          return;
        }
        const articles = this.articleList.map((item) => {
          const {
            totalAmountInStock, includeTax, locationName, ...filteredItem
          } = item;
          return filteredItem;
        })
        if ((this.orderNumber != '' && this.settingsFix == null) && this.buttonSubmited == true) {
          return
        }
        await this.$refs.addFile1.uploadFile();
        await this.$refs.addFile2.uploadFile();
        await this.$refs.addFile3.uploadFile();
        const object = {
          clientId: this.clientData.clientId,
          billingAddressDto: this.clientData.billingAddressDto,
          ordererDto: this.clientData.ordererDto,
          shippingAddressDto: this.clientData.shippingAddressDto,
          pickUpInStore: this.clientData.pickUpInStore,
          salesUserId: this.roleId,
          description: this.description,
          orderType: this.clientData.orderType,
          accountId: this.accountId,
          assignmentTeamNameId: this.assignmentTeamNameId,
          state: this.state,
          orderNumber: this.orderNumber != '' ? this.orderNumber + this.settingsFix : null,
          createFileDataDto: this.createFileDataDto,
          invoiceRemark: this.invoiceRemark,
          shippingCostRequestDto: this.shippings,
          orderItemInStoreDtos: articles,
          sellDate: this.sellDate,
        }
        this.$emit('createOrder', object)
        this.$refs.modal.hide()
        this.resetForm()
      } catch (error) {
        console.error('An error occurred during file upload:', error);
      } finally {
        this.loading = false;
        this.createFileDataDto = [];
      }
    },
    onCancel() {
      this.$refs.modal.hide()
      this.resetForm()
    },
    resetForm() {
      this.resetIsOrderNumberAvailable()
      this.$emit('resetArticlesFromModal')
      this.createFileDataDto = [];
      this.assignmentTeamNameId = null
      this.state = null
      this.orderNumber = '';
      this.settingsFix = null
      this.buttonSubmited = false
      this.taxRate = 0
      this.includeTax = false
      this.pickUpDate = null
      this.sellDate = null
      this.accountId = null
      this.roleId = null
      this.invoiceRemark = null
      this.shippings.cost = null
      this.shippings.currency = '€'
      this.shippings.accountId = null
      this.shippings.notes = ''
      this.shippings.paidAmount = null
      this.clientData.ordererDto.salutation = null;
      this.clientData.ordererDto.firstName = '';
      this.clientData.ordererDto.lastName = '';
      this.clientData.ordererDto.email = '';
      this.clientData.ordererDto.gender = null;
      this.clientData.ordererDto.telephoneNumber = '';
      this.clientData.billingAddressDto.street = '';
      this.clientData.billingAddressDto.houseNumber = '';
      this.clientData.billingAddressDto.doorNumber = '';
      this.clientData.billingAddressDto.postalCode = '';
      this.clientData.billingAddressDto.city = '';
      this.clientData.billingAddressDto.country = '';
      this.clientData.shippingAddressDto.street = '';
      this.clientData.shippingAddressDto.houseNumber = '';
      this.clientData.shippingAddressDto.doorNumber = '';
      this.clientData.shippingAddressDto.postalCode = '';
      this.clientData.shippingAddressDto.city = '';
      this.clientData.shippingAddressDto.country = '';
      this.clientData.pickUpInStore = false;
      this.clientData.orderType = null;
      this.clientData.clientId = '00000000-0000-0000-0000-000000000000'
    },
  },
}
</script>

    <style>

    </style>
