<template>
  <div>
    <div style="width: 100%;  padding-top: 55px; margin-top: 0px">
      <table
        class="team_table"
        :class="{'button--disabled': getArticleOrders.length == 0}"
      >
        <thead>
          <tr>
            <th>
              {{ $t('ArticleName') }}
            </th>
            <th>
              {{ $t("PickupDate") }}
            </th>
            <th>
              {{ $t("Created") }}
            </th>
            <th>
              {{ $t("AddToTransit") }}
            </th>
          </tr>
        </thead>
        <tbody v-if="orderId != ''">
          <tr
            v-for="(item, index) in filteredArticleOrders"
            :key="index"
            style="cursor: pointer;"
            class="hoverable-row"
            @click="matProps(item)"
          >
            <td>
              {{ item.articleNumber }}
            </td>
            <td>
              {{ fromatDate(item.pickUpDate) }}
            </td>
            <td>
              {{ fromatDate(item.created) }}
            </td>
            <td>
              <button
                v-b-modal.confirmation-modal
                class="editButton"
                @click="isTransitAvailable(item)"
              >
                {{ $t("Add") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ConfirmationModal
      v-if="tabIndex === 0"
      :title="$t('AddToTransit')"
      :body-text="bodyText"
      :is-confirmed="true"
      @Confirm="moveToTransit"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import ConfirmationModal from '@/modals/ConfirmationModal.vue'
// import { client } from '../../domainConfig'

export default {
  components: {
    ConfirmationModal,
  },
  // eslint-disable-next-line
  props: ['orderId', 'createOrder', 'orderStateOfOrder', 'orderItemIds', 'tabIndex'],
  data() {
    return {
      bodyText: null,
      orderItemId: null,
      isTransitTrue: null,
    }
  },
  computed: {
    ...mapGetters(['getArticleOrders',
      'getOrderMaterialsMoving',
      'getLoggedInUser',
      'getLocationsMoving',
      'getIsAddedToTransit',
      'getItemsMovementCount']),
    filteredArticleOrders() {
      return this.getArticleOrders.filter((item) => { return item.stateDtos[item.state].stateName !== 'Delivered' });
    },
  },
  mounted() {
    this.movingLocations()
  },
  methods: {
    ...mapActions(['orderMaterialInternalMoving', 'itemsCount', 'addLineItem', 'movingLocations', 'isAddedToTransit']),
    matProps(article) {
      this.materialPropId = article.materialId;
      this.classNameCh = article.colorName;
    },
    fromatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    isTransitAvailable(value) {
      this.orderItemId = value.orderItemId;
      this.isAddedToTransit({
        orderItemId: value.orderItemId,
        successCallback: (response) => {
          if (response == true) {
            this.bodyText = this.$t('AlreadyInTransit')
            this.isTransitTrue = true
          } else {
            this.bodyText = this.$t('MoveToTransit')
            this.isTransitTrue = false
          }
        },
      })
    },
    moveToTransit() {
      if (this.isTransitTrue == false) {
        this.addLineItem({
          orderItemId: this.orderItemId,
          successCallback: () => {
            this.itemsCount({
              state: 'Pending',
            })
            this.bodyText = null
          },
        })
      }
    },
  },
}
</script>

<style scoped>
.team_table td {
  padding: 16px 9px !important;
}
.vs__dropdown-toggle{
  padding: 0px !important;
}
.hoverable-row:hover {
  background-color: rgba(255, 39, 79, 0.1);
}
</style>
