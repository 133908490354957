import { render, staticRenderFns } from "./CreateOrderModal.vue?vue&type=template&id=3df6e1c1&scoped=true"
import script from "./CreateOrderModal.vue?vue&type=script&lang=js"
export * from "./CreateOrderModal.vue?vue&type=script&lang=js"
import style0 from "./CreateOrderModal.vue?vue&type=style&index=0&id=3df6e1c1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3df6e1c1",
  null
  
)

export default component.exports