<template>
  <div>
    <div class="filtering">
      <div class="search-toggle">
        <p>{{ $t("SearchBy") }}</p>
        <div>
          <p
            :class="{ active2: !toggleSearch }"
            @click="showOrderNumber()"
          >
            {{ $t("OrderNumber") }}
          </p>
          <p
            :class="{ active2: toggleSearch }"
            @click="showArticleNumber()"
          >
            {{ $t("ArticleNumber") }}
          </p>
        </div>
      </div>
      <div v-if="showw == true">
        <div>
          <div class="black">
            <b-icon-search
              v-if="searchOrder == ''"
              class="search1"
              style="position: absolute; left: 5px; top: 13px"
            />
            <!-- <b-icon-x-circle
              v-else-if="searchOrder != '' && !noSearch"
              class="search1"
              style="position: absolute; margin-top: -6px; right: 20px; top: 17px; cursor: pointer"
              @click="searchOrder = ''"
            /> -->
            <input
              v-model="searchOrder"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('OrderNumber')"
              :style="
                getFilteredOrderNumber.length > 0 && noSearch
                  ? 'border-radius: 8px 8px 8px 8px'
                  : ''
              "
              @keyup="inputChanged"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
            >
          </div>
          <div
            v-if="getFilteredOrderNumber.length > 0 && noSearch"
            ref="scrollContainer"
            class="dropdrop"
            :style="getFilteredOrderNumber.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getFilteredOrderNumber"
              :key="index"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="
                searchByOn(result);
                noSearch = false;
              "
            >
              <p style="margin: 0">
                {{ result.orderNumber }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showw == false">
        <div>
          <div class="black">
            <b-icon-search
              v-if="searchCN == ''"
              class="search1"
              style="position: absolute; left: 5px; top: 12px"
            />
            <!-- <b-icon-x-circle
              v-else-if="searchCN != '' && !noSearch"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="searchCN = ''"
            /> -->
            <input
              v-model="searchCN"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('ArticleNumber')"
              :style="getArticleNumberBySalesType.length > 0 && noSearchTwo ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged1"
              @keydown.down="onArrowDown1"
              @keydown.up="onArrowUp1"
            >
          </div>
          <div
            v-if="getArticleNumberBySalesType.length > 0 && noSearchTwo"
            ref="scrollContainer"
            class="dropdrop"
            style="
              display: inline-block;
              overflow: auto;
              position: absolute;
              background: white;
              width: 229px;
              box-shadow: none;
            "
            :style="getArticleNumberBySalesType.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getArticleNumberBySalesType"
              ref="options2"
              :key="index"
              :class="{ 'is-active': index === arrowCounter }"
              style="cursor: pointer"
              class="search-item"
              @click="searchByCn(result); noSearchTwo = false"
            >
              <p style="margin: 0">
                {{ result.articleNumber }}
              </p>
            </div>
            <div
              v-if="getArticleNumberBySalesType.length == 0"
              class="search-item"
            >
              <p>{{ $t('NoResult') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="cursor: pointer">
      <img
        v-b-tooltip.hover
        :title="$t('ClickForTransit')"
        src="@/assets/images/inMovement.png"
        style="width: 30px; margin-bottom: 10px"
        @click="goToTab2"
      >
      <span class="cart-counter-article">{{
        getItemsMovementCount ? getItemsMovementCount : 0
      }}</span>
      <span>{{ $t('FilterArticle') }}</span>
    </div>
    <div style="width: 100%; padding-top: 16px; margin-top: 0px">
      <table class="team_table">
        <thead>
          <tr>
            <th>{{ $t("OrderNumber") }}</th>
            <th>{{ $t("ClientName") }}</th>
            <th>{{ $t("Article") }}</th>
            <th>{{ $t("OrderType") }}</th>
            <th>{{ $t("SellDate") }}</th>
            <th>{{ $t("AddToTransit") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="order in getOrderByOrderItemId"
            :key="order.id"
          >
            <td>{{ order.orderNumber }}</td>
            <td>{{ order.clientName }}</td>
            <td>{{ order.articleNumber }}</td>
            <td>{{ $t(order.orderType) }}</td>
            <td>{{ fromatDate(order.salesDate) }}</td>
            <td>
              <button
                v-b-modal.confirmation-modal
                class="editButton"
                @click="isTransitAvailable(order)"
              >
                {{ $t("Add") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ConfirmationModal
      v-if="tabIndex === 1"
      :title="$t('AddToTransit')"
      :body-text="bodyText"
      :is-confirmed="true"
      @Confirm="moveToTransit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ConfirmationModal from '@/modals/ConfirmationModal.vue';
import moment from 'moment'

export default {
  components: {
    ConfirmationModal,
  },
  props: ['tabIndex'],
  data() {
    return {
      bodyText: null,
      orderItemId: null,
      isTransitTrue: null,
      toggleSearch: false,
      showw: true,
      searchOrder: '',
      searchCN: '',
      noSearch: false,
      noSearchTwo: false,
    };
  },
  computed: {
    ...mapGetters(['getItemsMovementCount', 'getOrderByOrderItemId', 'getIsAddedToTransit', 'getArticleNumberBySalesType', 'getFilteredOrderNumber']),
  },
  watch: {
    searchOrder(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value.trim() === '') {
          this.noSearch = false;
        // Do not call the method without a valid orderId
        } else if (!this.enterPressed) {
          this.loadOrderNumbers({
            salesType: 'Product',
            orderNumber: value,
          });
          this.noSearch = true;
        }
      }, 500);
    },
    searchCN(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value.trim() === '') {
          this.noSearchTwo = false;
        // Do not call the method without a valid orderItemId
        } else if (!this.enterPressed) {
          this.articleNumberLikeInternalMovingArticles({
            articleNumberLike: value,
          });
          this.noSearchTwo = true;
        }
      }, 500);
    },
  },
  mounted() {
    this.itemsCount({
      state: 'Pending',
    });
  },
  methods: {
    ...mapActions(['itemsCount', 'orderItemsByOrderItemId', 'addLineItem', 'isAddedToTransit', 'loadOrderNumbers', 'articleNumberLikeInternalMovingArticles']),
    goToTab2() {
      this.$emit('goToTab3');
    },
    fromatDate(value) {
      return value ? moment(value).format('DD-MM-YYYY') : '';
    },
    isTransitAvailable(value) {
      this.orderItemId = value.orderItemId;
      this.isAddedToTransit({
        orderItemId: value.orderItemId,
        successCallback: (response) => {
          if (response == true) {
            this.bodyText = this.$t('AlreadyInTransit');
            this.isTransitTrue = true;
          } else {
            this.bodyText = this.$t('MoveToTransit');
            this.isTransitTrue = false;
          }
        },
      });
    },
    moveToTransit() {
      if (this.isTransitTrue == false) {
        this.addLineItem({
          orderItemId: this.orderItemId,
          successCallback: () => {
            this.itemsCount({
              state: 'Pending',
            });
            this.bodyText = null;
          },
        });
      }
    },

    async searchByOn(value) {
      this.searchOrder = value.orderNumber
      this.noSearch = false
      await this.orderItemsByOrderItemId({
        orderId: value.orderId,
      })
    },

    async searchByCn(value) {
      this.searchCN = value.articleNumber
      this.noSearchTwo = false
      await this.orderItemsByOrderItemId({
        orderItemId: value.orderItemId,
      })
    },

    showOrderNumber() {
      this.toggleSearch = false;
      this.searchOrder = '';
      this.showw = true;
      this.orderItemsByOrderItemId({
        orderId: null,
      })
    },

    showArticleNumber() {
      this.toggleSearch = true;
      this.searchCN = '';
      this.showw = false
      this.orderItemsByOrderItemId({
        orderId: null,
      })
    },

    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredOrderNumber.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    onArrowDown1(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getArticleNumberBySalesType.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp1(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFilteredOrderNumber[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    inputChanged1(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByCn(this.getArticleNumberBySalesType[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
  },
};
</script>

<style scoped lang="scss">
.cart-counter-article {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0px 5px;
  font-size: 13px;
  position: relative;
  left: -8px;
  top: -11px;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.is-active {
  background-color: #dedede;
}
.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
  z-index:3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.active2{
    color: $base-color;
    font-weight: bold;
  }
</style>
