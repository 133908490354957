<template>
  <b-modal
    id="delete-quantity"
    ref="modal"
    header-class="headerModal"
    centered
    size="s"
    :title="`${$t('DeleteQuantity')}: ${articleNum}-${locationNameStock?.locationName}`"
    hide-footer
    @close="onCancel"
  >
    <b-form-group
      :label="$t('Quantity')"
    >
      <b-form-input
        v-model.number="quantity"
        :placeholder="$t('Quantity')"
        type="number"
      />
      <p style="color:red">
        {{ $t('MaxQuantity') }} is {{ maxQuantity }}
      </p>
    </b-form-group>
    <div
      class="buttonsEverywhere"
    >
      <button
        type="button"
        variant="none"
        style="margin-top: 20px;"
        class="buttonSubmit"
        @click="onSubmit"
      >
        {{ $t('Submit') }}
      </button>
      <b-button
        type="reset"
        variant="danger"
        class="buttonCancel"
        style="margin-right: 15px; margin-top: 20px; background: white; color: black; border-color: white;font-weight: 400;"
        @click="onCancel"
      >
        {{ $t('Cancel') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';


export default {
  components: {

  },
  props: ['maxQuantity', 'articleNum', 'locationNameStock'],
  data() {
    return {
      quantity: 0,
    }
  },
  computed: {
    ...mapGetters([]),

  },
  watch: {
    quantity(value) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (value < 0 || value == '') {
          this.quantity = 0;
        } else if (value > this.maxQuantity) {
          this.quantity = 0;
        }
      }, 300);
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions([]),

    async onSubmit() {
      if (this.quantity != 0) {
        const object = {
          articleNumber: this.articleNum,
          counter: this.quantity,
          locationId: this.locationNameStock.locationId,
        }
        this.$emit('deleteQuantity', object)
      }
      this.$refs.modal.hide()
      this.resetForm()
    },
    onCancel() {
      this.$refs.modal.hide()
      this.resetForm()
    },
    resetForm() {
      this.quantity = 0
    },
  },
}
</script>

      <style>

      </style>
