<template>
  <div>
    <b-modal
      id="add-prefix"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('AddPrefix/PostFix')"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-select-1"
          :label="$t('Name')"
          label-for="select-1"
        >
          <b-input
            v-model="$v.name.$model"
            :placeholder="$t('Write...')"
            :state="validateState('name')"
            aria-describedby="input-1-live-feedback"
            required
            @input="isPrefix(name)"
          />
          <p
            v-if="getIsPrefix == true"
            style="color: red;"
          >
            {{ $t("PrefixExists") }}
          </p>
          <p
            v-if="this.$v.$error"
            style="color: red;"
          >
            {{ $t("Required") }}
          </p>
        </b-form-group>
        <!-- <b-form-group
          :label="$t('Prefix-Postfix')"
        >
          <vue-select
            v-model="type"
            :options="settingsFixes"
            :placeholder="$t('Select')"
          />
        </b-form-group> -->

        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="primary"
            class="buttonSubmit"
            style="margin-top: 10px;"
            :disabled="getIsPrefix == true"
            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 10px; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate';
import {
  required,
}
from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  props: [],
  data() {
    return {
      name: null,
      type: 'Postfix',
      settingsFixes: ['Postfix', 'Prefix'],
    }
  },
  validations: {
    name: {
      required,
    },
  },
  computed: {
    ...mapGetters(['getIsPrefix']),
  },
  watch: {

  },
  mounted() {

  },
  methods: {
    ...mapActions(['loadIsPrefix', 'resetLoadIsPrefix']),
    isPrefix(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.loadIsPrefix({
          type: 'Postfix',
          name: value,
        })
      }, 500);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const object = {
        name: this.name,
        type: this.type,
      }
      this.$emit('prefixMethod', object)
      this.$refs.modal.hide()
      setTimeout(() => {
        this.resetForm()
      }, 10)
    },
    onCancel() {
      this.$refs.modal.hide()
      this.resetForm()
    },
    resetForm() {
      this.name = null
      this.type = 'Postfix'
      this.resetLoadIsPrefix()
      this.$nextTick(() => { this.$v.$reset() })
    },
  },
}
</script>

    <style lang="scss" scoped>
    </style>
